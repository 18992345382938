@use 'src/styles/variables' as *;

$widthWithoutSidebarEventListAndScrollbar: calc(75vw - (100vw - 100%) - $menu-left-width);
$widthWithoutEventListAndScrollbar: calc(75vw - (100vw - 100%));

.eventHeader {
  position: fixed;
  top: var(--navbar-height);
  right: 0;
  z-index: 2;
  height: 60px;
  width: $widthWithoutSidebarEventListAndScrollbar;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $shadeWhite;
  padding: 16px 48px;

  @media #{$small-screen} {
    width: $widthWithoutEventListAndScrollbar;
  }

  .eventTitle {
    font-weight: 600;
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .eventDataAndActions {
    display: flex;
    align-items: center;
    gap: 24px;

    .eventDate {
      font-weight: 500;
      font-size: 14px;
    }

    .actionIcons {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }
}

// Pour tout ajout de couleur dans la charte:
//   créer la variable scss
//   ajouter l'export correspondant en fin de fichier
//   ajouter la référence dans src/design-system/colors_v3.ts

$shadeBlack: rgba(0, 0, 0, 1);
$shadeHalf: rgba(39, 49, 66, 1);
$shade1: rgba(0, 21, 64, 1);
$shade2: rgba(18, 42, 89, 1);
$shade3: rgba(40, 65, 115, 1);
$shade4: rgba(77, 101, 153, 1);
$shade5: rgba(168, 187, 224, 1);
$shade6: rgba(208, 221, 245, 1);
$shade7: rgba(235, 241, 255, 1);
$shade8: rgba(246, 248, 254, 1);
$shade9: rgba(250, 251, 253, 1);
$shadeWhite: rgba(255, 255, 255, 1);

$primaryDark: rgba(36, 102, 178, 1);
$primaryDefault: rgba(69, 143, 230, 1);
$primaryLight: rgba(97, 164, 242, 1);
$primaryWashed: rgba(217, 235, 255, 1);
$primaryTransparent: rgba(69, 143, 230, 0.1);

$secondaryDark: rgba(0, 154, 131, 1);
$secondaryDefault: rgba(12, 193, 165, 1);
$secondaryLight: rgba(85, 211, 192, 1);
$secondaryWashed: rgba(206, 243, 237, 1);
$secondaryTransparent: rgba(12, 193, 165, 0.1);

$statusValid: rgba(12, 193, 165, 1);
$statusWarning: rgba(242, 186, 73, 1);
$statusInvalid: rgba(230, 69, 82, 1);
$statusNormal: rgba(69, 143, 229, 1);
$statusInfo: rgba(230, 230, 230, 1);
$statusDanger: rgba(212, 88, 88, 1);

$statusTransparentValid: rgba(12, 193, 165, 0.1);
$statusTransparentWarning: rgba(242, 186, 73, 0.1);
$statusTransparentInvalid: rgba(230, 69, 82, 0.1);

$labelLightGray: rgba(85, 88, 120, 1);
$labelGray: rgba(107, 122, 153, 1);
$labelDarkGray: rgba(70, 84, 112, 1);
$labelBrown: rgba(139, 87, 42, 1);
$labelGarnetRed: rgba(154, 9, 9, 1);
$labelRed: rgba(212, 88, 88, 1);
$labelOrange: rgba(228, 163, 73, 1);
$labelYellow: rgba(191, 178, 25, 1);
$labelGreen: rgba(9, 154, 132, 1);
$labelBlue: rgba(74, 144, 226, 1);
$labelNavyBlue: rgba(59, 115, 180, 1);
$labelPurple: rgba(136, 108, 230, 1);
$labelMagenta: rgba(193, 53, 193, 1);

$labelTransparentGray: rgba(107, 122, 153, 0.1);
$labelTransparentDarkGray: rgba(70, 84, 112, 0.1);
$labelTransparentBrown: rgba(139, 87, 42, 0.1);
$labelTransparentGarnetRed: rgba(154, 9, 9, 0.1);
$labelTransparentRed: rgba(212, 88, 88, 0.1);
$labelTransparentOrange: rgba(228, 163, 73, 0.1);
$labelTransparentYellow: rgba(191, 178, 25, 0.1);
$labelTransparentGreen: rgba(9, 154, 132, 0.1);
$labelTransparentBlue: rgba(74, 144, 226, 0.1);
$labelTransparentNavyBlue: rgba(59, 115, 180, 0.1);
$labelTransparentPurple: rgba(136, 108, 230, 0.1);
$labelTransparentMagenta: rgba(193, 53, 193, 0.1);

$dimmerLight: rgba(34, 45, 66, 0.1);
$dimmerMedium: rgba(34, 45, 66, 0.25);
$dimmerDark: rgba(34, 45, 66, 0.6);

$shadowDark: rgba(85, 88, 120, 0.26);
$shadowGrey: rgba(222, 226, 229, 0.2);

// Permet d'importer les variables SCSS dans le js, voir src/design-system/colors_v3.ts
:export {
  shadeBlack: $shadeBlack;
  shadeHalf: $shadeHalf;
  shade1: $shade1;
  shade2: $shade2;
  shade3: $shade3;
  shade4: $shade4;
  shade5: $shade5;
  shade6: $shade6;
  shade7: $shade7;
  shade8: $shade8;
  shade9: $shade9;
  shadeWhite: $shadeWhite;

  primaryDark: $primaryDark;
  primaryDefault: $primaryDefault;
  primaryLight: $primaryLight;
  primaryWashed: $primaryWashed;
  primaryTransparent: $primaryTransparent;

  secondaryDefault: $secondaryDefault;
  secondaryDark: $secondaryDark;
  secondaryLight: $secondaryLight;
  secondaryWashed: $secondaryWashed;
  secondaryTransparent: $secondaryTransparent;

  statusValid: $statusValid;
  statusWarning: $statusWarning;
  statusInvalid: $statusInvalid;
  statusNormal: $statusNormal;
  statusInfo: $statusInfo;
  statusDanger: $statusDanger;

  statusTransparentValid: $statusTransparentValid;
  statusTransparentWarning: $statusTransparentWarning;
  statusTransparentInvalid: $statusTransparentInvalid;

  labelLightGray: $labelLightGray;
  labelGray: $labelGray;
  labelDarkGray: $labelDarkGray;
  labelBrown: $labelBrown;
  labelGarnetRed: $labelGarnetRed;
  labelRed: $labelRed;
  labelOrange: $labelOrange;
  labelYellow: $labelYellow;
  labelGreen: $labelGreen;
  labelBlue: $labelBlue;
  labelNavyBlue: $labelNavyBlue;
  labelPurple: $labelPurple;
  labelMagenta: $labelMagenta;

  labelTransparentGray: $labelTransparentGray;
  labelTransparentDarkGray: $labelTransparentDarkGray;
  labelTransparentBrown: $labelTransparentBrown;
  labelTransparentGarnetRed: $labelTransparentGarnetRed;
  labelTransparentRed: $labelTransparentRed;
  labelTransparentOrange: $labelTransparentOrange;
  labelTransparentYellow: $labelTransparentYellow;
  labelTransparentGreen: $labelTransparentGreen;
  labelTransparentBlue: $labelTransparentBlue;
  labelTransparentNavyBlue: $labelTransparentNavyBlue;
  labelTransparentPurple: $labelTransparentPurple;
  labelTransparentMagenta: $labelTransparentMagenta;

  dimmerLight: $dimmerLight;
  dimmerMedium: $dimmerMedium;
  dimmerDark: $dimmerDark;
}

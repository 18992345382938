@use 'src/styles/variables' as *;

.backgroundVeil {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $dimmerMedium;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $z-index-fixed-above;

  transition: opacity $opening-transition;
  opacity: 1;
  &.closed {
    opacity: 0;
  }

  &.hidden {
    display: none;
  }
}

.content {
  display: flex;
  max-height: 100%;

  transition: transform $opening-transition;
  transform: translateY(0);
  &.closed {
    transform: translateY(100%);
  }
}

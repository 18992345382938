@use 'src/styles/variables' as *;

.container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  position: relative;
  margin: 5px 0;

  .item {
    display: flex;
    align-items: center;
    height: 40px;
    gap: 8px;
    opacity: 1;
    transition: opacity 200ms ease-in-out;
    font-size: 1rem;
    min-width: 160px;
    padding: 0 16px;
    color: $shade2;
    border: 2px solid $shade5;
    border-radius: 20px;

    &.danger {
      border-color: $labelRed;
    }

    .labelContent {
      display: inline-flex;
      margin-left: 0.5rem;
      align-items: center;
      overflow: hidden;

      .title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &.danger {
          color: $labelRed;
        }
      }
    }
    .infoIcon {
      margin-left: auto;
    }
  }

  .hoveredItem {
    opacity: 0;
    position: absolute;
    inset: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 4px;
    border: 2px solid $shade5;
    border-radius: 20px;
    background-color: $shade2;
    color: $shadeWhite;
    transition: opacity 200ms ease-in-out;

    .iconContainer {
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 8px;

      &.iconData {
        cursor: default;
      }
    }
  }

  &:hover {
    .item {
      @media #{$small-screen} {
        cursor: pointer;
      }
    }

    .hoveredItem {
      opacity: 1;

      .iconContainer:hover {
        opacity: 0.5;
      }
    }
  }
}

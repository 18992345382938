@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.container {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 4px;
  border-radius: 8px;
  border: 1px solid $ds-light-5;
  flex-direction: column;
  margin: 16px 0;
  background-color: $ds-light-9;

  @media #{$small-screen} {
    flex-direction: column;
  }

  &.loading {
    height: 70px;
  }
}

.loadingOverlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba($ds-light-7, 0.5);
  z-index: $z-index-overlap;
}

.contactType {
  padding: 12px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .check {
    margin: 0.2rem 0;
    flex-grow: 1;
    width: 50%;
  }
}

.contactOrganization {
  padding: 0 12px 12px 12px;
  width: 100%;
}

.contactGreetingFormLayout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.infos {
  display: flex;
  height: 100%;
  padding: 12px;
  background-color: $ds-light-7;
  width: 100%;
  flex-direction: row;

  .inactive & {
    background: none;
  }

  .infoContent {
    flex-grow: 1;
    display: block;
    width: 100%;
    overflow: hidden;

    .name {
      .inactive & {
        color: $ds-dark-4;
      }

      text-transform: uppercase;
      white-space: nowrap;
    }

    .specialty {
      text-transform: capitalize;
      color: $ds-dark-4;
      font-weight: 500;

      .privateTag {
        width: min-content;
      }
    }
  }

  .infoAction {
    display: inline-flex;
    margin: auto;
    margin-left: 1rem;
    gap: 0.5rem;
  }
}

@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

$animationDuration: 0.5s;
$infosPanelExpandedWidth: 25vw;
$infosPanelShrinkedWidth: 1.5vw;

@keyframes slideOut {
  to {
    transform: translateX(-23.5vw);
  }
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(-23.5vw);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

@keyframes shrink {
  from {
    min-width: $infosPanelExpandedWidth;
  }
  to {
    min-width: $infosPanelShrinkedWidth;
  }
}
@keyframes expand {
  from {
    min-width: $infosPanelShrinkedWidth;
  }
  to {
    min-width: $infosPanelExpandedWidth;
  }
}

.loading {
  position: relative;
  height: 100%;
  width: 100%;
}

.container {
  display: flex;
  flex-grow: 1;

  @media #{$small-screen} {
    flex-direction: column;
    margin-left: 0vw;
  }
}

.content {
  width: 100%;
  height: 100%;
  min-width: 1px;
}

.patientInfo {
  background-color: var(--fw-color-shades-white);
  transition: border $animationDuration ease-out;

  @media #{$not-mobile} {
    position: fixed;
    z-index: 2;
    width: $infosPanelExpandedWidth;
    left: $menu-left-width;
    bottom: 0;
    top: $navbar-height;
  }

  &.medicalEventContext {
    @media #{$small-screen} {
      display: none;
    }
  }

  > :first-child {
    box-shadow: 9px 0px 17px 0px $color-content-dark-3;
    height: 100%;

    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    overflow-y: scroll;
    overflow-x: hidden;
  }

  &.foldable {
    border-right: var(--fw-color-primary-default) 4px solid;
  }

  // Désactivation des animations au chargement du layout
  &.disableAnimation {
    &.hide {
      transform: translateX(-23.5vw) !important;
      > :first-child {
        opacity: 0 !important;
      }
    }
    &.show {
      transform: translateX(0) !important;
      > :first-child {
        opacity: 100 !important;
      }
    }
  }

  animation-duration: $animationDuration;
  animation-fill-mode: forwards;

  > :first-child {
    animation-duration: $animationDuration;
    animation-fill-mode: forwards;
  }

  &.show {
    animation-name: slideIn;
    > :first-child {
      animation-name: fadeIn;
    }
  }
  &.hide {
    animation-name: slideOut;
    > :first-child {
      animation-name: fadeOut;
    }
  }
}

// Cette div sert de faux patientInfos pour rendre l'animation de slide in/out plus fluide
.patientInfoDummy {
  //Désactivation des animations au chargement du layout
  &.disableAnimation {
    &.shrink {
      min-width: $infosPanelShrinkedWidth !important;
    }
    &.expand {
      min-width: $infosPanelExpandedWidth !important;
    }
  }

  animation-duration: $animationDuration;
  animation-fill-mode: forwards;
  &.expand {
    animation-name: expand;
  }
  &.shrink {
    animation-name: shrink;
  }
}

.grip {
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  right: -0.8rem;
  top: 2%;
  background-color: var(--fw-color-primary-default);
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border-radius: 20px;

  &:hover {
    background-color: var(--fw-color-primary-light);
  }
}

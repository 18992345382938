@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.date {
  color: $color-grey;
  font-size: toRem(13px);
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 20px;
}
.text {
  color: $color-grey;
  text-align: center;
}

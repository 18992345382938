@import '../../../../styles/variables/media-queries';
@import '../../../../styles/variables/sizes';

.pdfViewer {
  width: 45vw;
  min-height: 50vh;

  @media #{$small-screen} {
    width: 90vw;
  }
}

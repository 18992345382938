@use 'src/styles/variables' as *;

.taskListContainer {
  .placeholderWrapper {
    margin-bottom: 3.125rem;
  }

  .actionButtonsWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0.75rem 0;
  }

  .tableWrapper {
    @media #{$extra-small-screen} {
      overflow-x: auto;
    }
  }

  .showMoreButtonWrapper {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
  }

  .accordionTitle {
    @include typo-base();
    font-weight: 600;
    font-size: toRem(18px);
    white-space: nowrap;
    @media #{$medium-screen} {
      @media #{$not-mobile} {
        font-size: 1.2vw;
      }
    }
    margin-right: toRem(10px);
    @media #{$medium-screen} {
      margin-right: toRem(5px);
    }
  }
}

@use 'src/styles/variables' as *;

.emptyTeam {
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-row-gap: 16px;
  justify-items: center;
  margin-top: 16px;

  .text {
    @include typo-base;
    font-size: toRem(16px);
    line-height: toRem(24px);
    color: var(--fw-color-shades-shade4);
  }
}

.teamList {
  overflow-y: auto;
  max-height: 1300px;
}

@import '../../../../styles/variables/mixins';
@import '../../../../styles/variables/sizes';

.userItem {
  display: flex;
  flex-direction: column;
  width: 25%;

  & > *:first-child {
    margin-bottom: 8px;
  }

  .svgText {
    font-size: toRem(16px);
    font-weight: 600;
  }

  .itemText {
    @include typo-base;
    font-size: toRem(12px);
    color: var(--fw-color-shades-shade4);
    width: toRem(82px);
    text-align: start;
  }
}

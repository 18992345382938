@import '../../../../styles/variables/sizes';

.item {
  display: flex;
  flex: 1;
  height: 42px;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;

  .visibility {
    display: flex;
    flex-basis: 3%;
    align-items: flex-start;
  }

  .titleContainer {
    display: flex;
    flex-basis: 30%;
    justify-content: center;
    flex-direction: column;

    .title {
      font-weight: bold;
    }

    .ownerName {
      font-weight: 500;
      font-size: toRem(12px);
      color: var(--fw-color-shades-shade4);
      margin-top: 8px;
    }
  }

  .identifier {
    display: flex;
    flex-basis: 10%;
  }

  .tags {
    display: flex;
    flex-basis: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

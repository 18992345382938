@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.header {
  border-radius: 8px;
  background-color: $ds-light-7;
  cursor: pointer;
  .title {
    color: $ds-dark-2;
  }
  .subtitle {
    color: $ds-dark-4;
    font-weight: 500;
  }
}
.content {
  padding: 12px;
  @media #{$not-mobile} {
    padding: 24px;
  }
}

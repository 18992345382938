@use 'src/styles/legacy/variables' as *;

.configValues {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.inputContainer {
  width: 45%;
}

.warning {
  color: $color-warning;
}

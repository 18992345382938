@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

$chevron-height: 8px;
.chevron {
  position: relative;
  margin-right: 1rem;
  margin-left: auto;
  width: 0;
  height: 0;
  border-left: $chevron-height solid transparent;
  border-right: $chevron-height solid transparent;
  border-top: $chevron-height solid #f00;

  margin-bottom: -#{$chevron-height};
}

@use './sizes' as *;
@use './media-queries' as *;

@mixin typo-base() {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  letter-spacing: normal;
}
@mixin typo-input-size-small() {
  font-size: toRem(13px);
}
@mixin typo-input-size-regular() {
  font-size: toRem(14px);
}
@mixin typo-input-size-big() {
  font-size: toRem(16px);
}

@mixin typo-input-value() {
  font-weight: 600;
}

@mixin typo-input-placeholder() {
  font-weight: 500;
}

@mixin navbarStyle() {
  position: fixed;
  top: 0;
  width: $remaining-width-without-left-menu;
  z-index: 10;
  @media #{$small-screen} {
    left: 0;
    width: 100%;
  }
}

@mixin ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@import '../../../../styles/variables/media-queries';
@import '../../../../styles/variables/sizes';
@import '../../../../styles/variables/z-index';

.banner {
  position: fixed;
  z-index: $z-index-max;
  width: 100%;
  padding-left: $menu-left-width;
  @media #{$small-screen} {
    margin-left: 0px;
  }
}

.cardContent {
  padding: 20px;

  .checkboxesContainer {
    display: flex;
    flex-direction: column;
    margin-top: 35px;
    gap: 15px;

    .checkbox {
      display: flex;
      align-items: flex-start;
      gap: 15px;
    }
  }
}

@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.observations {
  margin: 2rem;
  min-height: 300px;
  border-radius: 12px;

  @include box_shadow(0, 18px, 30px, 0px, transparentize($color-background-blue-3, 0.7));

  @media #{$small-screen} {
    margin: 0;
  }
}

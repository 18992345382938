@use 'src/styles/legacy/variables' as *;

.indicator {
  width: 100%;
  height: 5px;

  // Couleur par default
  background-color: var(--fw-color-shades-shade4);

  &.consultation_note {
    background-color: $medical-event-blue-color;
  }
  &.discharge_summary_note,
  &.surgical_operation_note {
    background-color: $medical-event-green-color;
  }
}

@use 'src/styles/variables' as *;

.medicalEventsContainer {
  overflow-y: auto;

  .medicalEventContainer {
    border-top: 1px solid $shade7;
    padding: 0 1rem;

    &.withDocs {
      padding-bottom: 1.5rem;
    }

    .medicalEventHeaderContainer {
      display: flex;
      align-items: center;
      gap: 8px;
      margin: 16px 0;

      .medicalEventDescription {
        display: flex;
        align-items: center;
        width: 100%;

        h4 {
          @include ellipsis();
          font-size: 16px;
          font-weight: 600;
        }

        .medicalEventDate {
          color: $shade3;
          font-size: 12px;
          font-weight: 500;
          margin-left: auto;
        }
      }
    }

    .withoutDocs {
      color: $labelGray;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      margin-bottom: 24px;
    }

    .documentItems {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }
}

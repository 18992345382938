.container {
  position: relative;

  .dropdown {
    position: absolute;
    top: 0;
    right: 40px;
  }
}

.listItem {
  display: flex;
  align-items: center;

  .label {
    margin-left: 10px;
  }
}

.item {
  display: flex;
  flex: 1;
  height: 42px;
  justify-content: space-between;
  align-items: center;

  .title {
    display: flex;
    align-items: flex-start;
    font-weight: bold;
  }
}

@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.container {
  display: flex;
  align-items: center;
  border-radius: 16px;
  border: solid 1px $ds-light-5;
  background-color: white;
  padding: 8px 16px;
  width: fit-content;
  cursor: pointer;
}

.label {
  font-family: Montserrat;
  font-size: toRem(12px);
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: var($ds-dark-2);
  margin-right: 8px;
}

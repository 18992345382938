@import '../../../../styles/variables/media-queries';
@import '../../../../styles/variables/sizes';

.item {
  display: flex;
  flex: 1;
  height: 42px;
  align-items: center;
  max-width: 100%;

  @media #{$not-mobile} {
    justify-content: space-between;
  }

  .visibility {
    display: flex;
    flex-basis: 3%;
    align-items: flex-start;
  }

  .titleContainer {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    @media #{$not-mobile} {
      flex-basis: 50%;
    }

    .deceasedAndMinorLabelContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 4px;
      padding-left: 20px;
      gap: 16px;
    }

    .titleRow {
      display: flex;
      flex-direction: row;
      align-items: center;

      .title {
        margin: 0 8px 0 20px;
        font-weight: 600;
        font-size: toRem(16px);
        color: var(--fw-color-shades-shade2);

        &.titleDeceased {
          color: var(--fw-color-shades-shade4);
        }
      }

      .age {
        display: flex;
        font-weight: 500;
        font-size: toRem(16px);
        color: var(--fw-color-shades-shade4);

        @media #{$small-screen} {
          justify-content: flex-end;
        }

        .ageLabel {
          margin-inline-end: 0.25rem;

          @media #{$small-screen} {
            font-weight: normal;
          }
        }

        .birthdateLabel {
          @media #{$small-screen} {
            display: none;
          }
        }
      }
    }
  }
  .tags {
    display: none;

    @media #{$not-mobile} {
      display: flex;
      flex-basis: 70%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .ownerName {
    font-weight: 500;
    font-size: toRem(12px);
    color: var(--fw-color-shades-shade4);
    margin: 4px 8px 0 20px;
  }
}

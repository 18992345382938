@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.mobileNavigationMenu {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999999999999999999;

  transform: translateX(100%);
  transition:
    transform $opening-transition,
    opacity 0.3s ease-in-out;

  opacity: 0;
  background-color: var(--fw-color-primary-default);

  &.open {
    transform: translateX(0);
    opacity: 1;
  }

  .openMenuHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    margin: 16px 16px 40px;
    padding: 16px;
  }

  .logoContainer {
    display: flex;
    align-items: center;

    .label {
      display: inline-flex;
      margin-left: 4px;
      color: var(--fw-color-shades-white);
      font-size: toRem(13px);
      font-weight: 600;
      letter-spacing: 1px;
    }
  }

  .currentUser {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px;
    padding: 16px;
    overflow: hidden;

    .userContainer {
      display: flex;
      align-items: center;
    }

    .label {
      display: inline-flex;
      margin-left: 8px;
      color: var(--fw-color-shades-white);
      font-size: toRem(16px);
      font-weight: 600;
      line-height: toRem(24px);
      letter-spacing: 1px;
      text-overflow: ellipsis;
    }
  }

  .menuItem {
    margin: 16px;
    padding: 16px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 8px;

    &.selected {
      background-color: var(--fw-color-primary-dark);
    }

    .label {
      margin-left: 1rem;
      color: var(--fw-color-shades-white);
      font-size: toRem(16px);
      font-weight: 600;
      line-height: toRem(24px);
      letter-spacing: 1px;
    }
  }

  .info {
    margin: 40px 0 auto;

    .job {
      display: block;
      text-align: center;
      color: var(--fw-color-shades-white);
      font-size: toRem(12px);
      font-weight: 500;
      line-height: toRem(16px);
      letter-spacing: 1px;
      text-overflow: ellipsis;
    }

    .name {
      display: block;
      text-align: center;
      color: var(--fw-color-shades-white);
      font-size: toRem(16px);
      font-weight: 600;
      line-height: toRem(24px);
      letter-spacing: 1px;
      text-overflow: ellipsis;
    }

    .doctors {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 16px;
      margin-top: 8px;
      flex-direction: row;
      justify-content: center;
      gap: 8px;
    }
  }
}

.logoutButton {
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    opacity: 1;
  }
}

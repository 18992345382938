@import '../../../styles/variables/z-index';
.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.questionContainer {
  width: 49%;
}
.dragLayer {
  position: fixed;
  pointer-events: none;
  z-index: $z-index-overlap;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.top {
  position: fixed;
  width: 100%;
  height: 5px;
  height: 15px;
  background-color: rgba(0, 0, 0, 0.1);
  transform: translateY(-5px);
  text-align: center;
}
.bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 15px;
  background-color: rgba(0, 0, 0, 0.1);
  text-align: center;
}

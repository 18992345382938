@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.actions {
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  width: 100%;
  justify-content: end;
}

.content {
  display: grid;
  grid-auto-flow: column;
  overflow: hidden;
  height: 100%;
}

.column {
  height: 100%;
  padding: 1rem;
}

.panel {
  background-color: $twGray50;
  overflow-y: scroll;
  width: 30rem;
  height: 100%;
  flex-grow: 0;
  flex-shrink: 0;

  &.history {
    background-color: $twGray100;
  }
}

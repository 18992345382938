@use 'src/styles/base' as *;
@use 'src/styles/legacy/variables' as *;

.input {
  max-width: 150px;
  border: none;
  background-color: transparent;
  color: $ds-white;
  caret-color: $ds-white;
  font-family: $font-family;
  font-weight: 600;
}

@use 'src/styles/variables' as *;

.container {
  background-color: $shadeWhite;
  width: 100vw;
  height: 100vh;
}

.backWrapper {
  display: flex;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 2em;
  align-items: center;
}

.backReturnText {
  font-weight: 600;
  color: $shade3;
  font-size: 1.2em;
  margin-left: 1em;
}

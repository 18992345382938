.buttonWrapper {
  margin-right: 1rem;

  .dropdownWrapper {
    position: absolute;
    margin-top: 0.5rem;

    .dropdownItemWrapper {
      .dropdownItemLabelWrapper {
        flex: 1 1;
        white-space: normal;
      }

      .dropdownItemActionsWrapper {
        padding: 0 10px;
      }
    }
  }
}

@use './color_v1' as *;
@use './colors_v2' as *;

@mixin box_shadow(
  $x: 0,
  $y: 18px,
  $blur-radius: 30px,
  $spread-radius: 0px,
  $color: $color-grey-border
) {
  box-shadow: $x $y $blur-radius $spread-radius $color;
}

@mixin elevation($elevation, $direction: 'bottom') {
  @if $elevation == 1 {
    @if $direction == 'right' {
      box-shadow: 4px 0px 7px 0px $color-content-dark-3;
    } @else {
      box-shadow: 1px 5px 7px 0px $color-content-dark-3;
    }
  } @else if $elevation == 2 {
    @if $direction == 'right' {
      box-shadow: 7px 0px 7px 2px $color-content-dark-3;
    } @else {
      box-shadow: 2px 7px 7px 2px $color-content-dark-3;
    }
  } @else if $elevation == 3 {
    @if $direction == 'right' {
      box-shadow: 12px 0px 7px 2px $color-content-dark-3;
    } @else {
      box-shadow: 3px 11px 7px 2px $color-content-dark-3;
    }
  } @else {
    box-shadow: 2px 1px 12px 3px red;
  }
}

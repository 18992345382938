@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.container {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  border-right: 1px solid var(--fw-color-shades-shade7);
  min-height: 100%;
  &.fullPageMode {
    min-height: $viewport-height-without-navbar;
  }

  .actionsHeader {
    background-color: var(--fw-color-shades-shade8);
    z-index: 1;
    @media #{$not-mobile} {
      top: 0;
      position: sticky;
    }
    &.fullPageMode {
      top: $navbar-height;
    }
  }

  .listContent {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    background-color: $color-background-light-1;

    /* ??? */
    position: relative;

    @media #{$small-screen} {
      overflow-y: unset;
      padding-bottom: 100px;
      top: 0;
    }

    .selectAllButton {
      margin: 10px;
      display: flex;
    }
    .intel {
      margin: 0 auto;
      color: $color-info;
      font-weight: 500;
    }
  }
}
.loader {
  margin: auto;
}

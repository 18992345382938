@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

$border-radius: 40px;

.container {
  display: flex;
}
.chipContainer {
  z-index: $z-index-base;
}
.actions {
  padding-left: calc($border-radius / 2);
  margin-left: calc($border-radius / -2);
  height: $border-radius;
  border-left: 0;
  border-radius: $border-radius;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  transition: border-color 1s ease-in-out;

  &.light {
    color: $color-content-light-1;
    border: none;
  }

  &.dark {
    color: $color-content-dark-1;
    border-color: transparentize($color-border-dark-2, 0.3);
  }

  &.collapsed {
    border-color: transparent;
    display: none;
  }
  &:not(.collapsed) {
    background-color: $ds-white;
  }
}

@use 'src/styles/legacy/variables' as *;

.contactSuggestion {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;

  .infos {
    flex-grow: 2;
    .title {
      margin-right: 0.3rem;
    }
    .firstName {
      text-transform: capitalize;
    }
    .familyName {
      margin-left: 0.3rem;
      text-transform: uppercase;
    }
    .profession {
      color: $ds-dark-4;
      font-size: 0.8rem;
    }
  }
  .tag {
    font-size: 0.8rem;
  }
  .organization {
    color: $ds-dark-4;
    font-size: 0.8rem;
    font-weight: normal;
  }

  &:hover {
    .profession {
      color: $ds-light-8;
    }
    .organization {
      color: $ds-light-8;
    }
  }
}

.background {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.container {
  width: 850px;
  display: flex;
  flex-direction: row;
}

.logo {
  height: 160px;
  width: 160px;
  margin-right: 40px;
  margin-top: 20px;
  background-image: url('../../assets/images/background/logo-follow.svg');
  background-repeat: no-repeat;
  background-size: contain;
  flex-shrink: 0;
}
.title {
  font-size: 40px;
}

@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.container {
  font-weight: 600;
  text-align: center;
  border-radius: 12px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  &.small {
    padding: 0 3px;
    font-size: toRem(10px);
    height: toRem(16px);
    min-width: toRem(16px);
  }

  &.large {
    padding: 0 8px;
    font-size: toRem(16px);
    height: toRem(24px);
    min-width: toRem(24px);
  }

  &.light {
    color: var(--fw-color-shades-white);
    background-color: var(--fw-color-primary-default);
  }

  &.dark {
    color: var(--fw-color-shades-white);
    background-color: var(--fw-color-shades-shade3);
  }

  &.zero-light {
    color: var(--fw-color-shades-white);
    background-color: $color-background-blue-3;
  }

  &.zero-dark {
    color: var(--fw-color-shades-white);
    background-color: var(--fw-color-shades-shade4);
  }
}

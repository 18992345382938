@import '../../../..//styles/variables/media-queries';
@import '../../../../styles/variables/z-index';

.container {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 4px;
  border-radius: 8px;
  border: 1px solid var(--fw-color-shades-shade5);
  flex-direction: column;
  margin: 16px 0;
  background-color: var(--fw-color-shades-white);

  @media #{$small-screen} {
    flex-direction: column;
  }

  &.loading {
    height: 70px;
  }
  &.inactive {
    opacity: 0.7;
  }
}

.loadingOverlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--fw-color-shades-shade7);
  z-index: $z-index-overlap;
}

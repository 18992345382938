$dark: var(--fw-color-shades-shade2);
$light: var(--fw-color-shades-white);

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  > * {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
  .selected {
    margin-left: auto;
  }
}

@use 'src/styles/variables' as *;

.loader {
  display: flex;
  justify-content: center;
}

.noResult {
  width: 100%;
  text-align: center;
  padding: 0.5rem;
  color: $shade2;
}

@use 'src/styles/variables' as *;

.container {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;

  .dropdown {
    position: absolute;
    z-index: 1;
    left: 0;

    &.bottom {
      top: calc(100% + 5px);
    }

    &.top {
      bottom: calc(100%);
    }
  }
}

.label {
  font-size: 12px;
  column-span: 2;

  &.light {
    color: $shade5;
  }

  &.dark {
    color: $shade4;
  }
}

.icons {
  position: absolute;
  right: 0px;
  bottom: 4px;
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  align-items: end;
  padding-bottom: 8px;
  padding-right: 8px;

  .icon {
    cursor: pointer;
  }
}

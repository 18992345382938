@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.showMore {
  @include typo-base();
  margin: 16px 0px 20px 0px;
  color: $color-brand-primary;
  display: flex;
  justify-content: center;
  a:visited {
    color: $color-brand-primary;
  }
  a:link {
    color: $color-brand-primary;
  }
}

.label {
  margin-right: 10px;
}

@use 'src/styles/variables' as *;

.container {
  width: 100%;
  display: flex;
  position: fixed;
  bottom: 30px;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
  z-index: $z-index-max;
}

@use 'src/styles/legacy/variables' as *;

.container {
  color: $color-info;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .icon {
    margin-right: 15px;
  }
}

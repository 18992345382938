@import '../../../../../../styles/variables/z-index';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 0.25rem;
  height: 3.5rem;
  cursor: pointer;

  &:hover,
  &.active {
    background: rgba($color: white, $alpha: 0.2);
  }
}

.spacer {
  position: relative;
  flex-shrink: 0;
  min-width: 1.5rem;
  margin: auto;
}

.status {
  position: absolute;
  top: -0.125rem;
  right: -0.25rem;
  border-radius: 9999px;
  width: 0.75rem;
  height: 0.75rem;

  &.warning {
    background-color: var(--fw-color-status-invalid);
  }

  &.valid {
    background-color: var(--fw-color-status-valid);
  }
}

.notification {
  position: absolute;
  top: -0.75rem;
  right: -0.75rem;
  border-radius: 9999px;
  text-align: center;
  z-index: $z-index-overlap;
  width: 1rem;
  height: 1rem;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1rem;
}

@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.container {
  @media #{$not-mobile} {
    height: 100%;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 0% 1fr;
    grid-template-areas: 'filters list';

    &.filterable {
      grid-template-columns: 30% 1fr;
      grid-template-areas: 'filters list';
    }
  }
  @media #{$small-screen} {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}

.filters {
  padding: 10px;
  background-color: var(--fw-color-shades-shade2);
  overflow-y: scroll;
  @media #{$not-mobile} {
    grid-area: filters;
  }
  @media #{$small-screen} {
    padding: 0px 10px;
    overflow: visible;
  }
}

.list {
  overflow-y: auto;
  @media #{$not-mobile} {
    grid-area: list;
    position: relative;
  }
  @media #{$small-screen} {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  background-color: var(--fw-color-shades-white);
}

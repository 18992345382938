.important {
  cursor: pointer;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.label {
  margin-right: 5px;
}

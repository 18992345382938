@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.container {
  background-color: $ds-light-6;
  padding: 1rem;
  @media #{$small-screen} {
    padding: 0.5rem;
  }
}

.content {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;

  & > *:not(:last-child) {
    margin-right: toRem(16px);
  }

  .fileLoad {
    display: none;
  }

  @media #{$small-screen} {
    .fileLoad {
      display: block;
      justify-self: flex-start;
      margin: 5px 0;
      .browse {
        display: none;
      }
    }
  }
}

.label {
  margin-right: 8px;
}

.counter {
  @include typo-base();
  font-size: toRem(16px);
  font-weight: 600;
  color: var(--fw-color-shades-white);
  background-color: var(--fw-color-shades-shade2);
  height: 24px;
  width: 100%;
  padding: 5px 10px;
  border-radius: 50%;
}

.placeholderLabel {
  width: 3rem;
}

.separator {
  height: 40px;
  width: 2px;
  border-radius: 1px;
  background-color: var(--fw-color-shades-shade5);
}

@use 'src/styles/variables' as *;

.cardContent {
  padding: 20px;
  .extraFeesContainer {
    width: fit-content;
    margin-top: 35px;

    .label {
      font-size: toRem(12px);
      color: $shade4;
    }

    > div {
      width: 60%;
    }
  }
}

@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

$halo-color1: $color-background-blue-4;
$halo-color2: $color-background-blue-3;
$transparent: rgba(255, 255, 255, 0);

/**
 * Example `@include halo($position)`
 * `$position` can be `start` or `end`. Default to `start`
**/
@mixin halo($position: 'end', $color: $halo-color1) {
  @if ($position == 'end') {
    background-image: linear-gradient(to left, $color, $transparent);
    right: 0;
  } @else {
    background-image: linear-gradient(to right, $color, $transparent);
    left: 0;
  }
  top: 0;
  width: 20px;
  height: 100%;
  opacity: 1;
  z-index: $z-index-overlap;
  position: absolute;
  pointer-events: none;
}

.container {
  position: relative;
  display: flex;

  @media #{$small-screen} {
    width: calc(100% - 50px);

    &.variant1 {
      &.withHalo {
        &::before {
          content: '';
          @include halo('start', $halo-color1);
        }

        &::after {
          content: '';
          @include halo('end', $halo-color1);
        }
      }
    }
    &.variant2 {
      &.withHalo {
        &::before {
          content: '';
          @include halo('start', $halo-color2);
        }

        &::after {
          content: '';
          @include halo('end', $halo-color2);
        }
      }
    }
  }
}
.filters {
  position: relative;
  padding: 4px 0 4px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box;

  & > *:not(:last-child) {
    margin-right: toRem(16px);
  }

  @media #{$small-screen} {
    margin-top: 0;
    flex-wrap: nowrap;
    overflow-x: auto;
    > * {
      margin-top: 0;
    }
  }
}

@import '../../../../../styles/variables/sizes';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0px;
  font-size: toRem(13px);
}

.label {
  flex-grow: 1;
  text-align: left;
}

.value {
  text-align: right;
  font-size: toRem(14px);
  font-weight: 600;
}

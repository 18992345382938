@use 'src/styles/variables' as *;

.bottomPanelContent {
  display: flex;
  height: 100%;

  .filtersContainer {
    padding: 1.5rem;
    flex: 1 1;

    .filters {
      display: flex;
      flex-direction: column;
      font-weight: 600;
      gap: 16px;
    }
  }

  .listContainer {
    position: relative;
    flex: 3 1;
    background-color: $shadeWhite;
    overflow-y: auto;
  }
}

@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.label {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
}
.checkbox {
  position: relative;
  display: flex;
}

.tick {
  position: absolute;
  width: 50%;
  height: 50%;
  top: 0;
  transform: translateY(50%) translateX(50%);
  left: 0;
  display: none;
  pointer-events: none;
  cursor: pointer;
  &.checked {
    display: block;
    fill: var(--fw-color-shades-white);
  }
  &:disabled {
    fill: var(--fw-color-shades-white);
  }
}

.input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;

  border: 2px solid var(--fw-color-shades-shade5);
  border-radius: 6px;
  height: 24px;
  width: 24px;
  background: var(--fw-color-shades-white);
  margin: 0px;

  &.checked {
    background: var(--fw-color-primary-default);
    border-color: var(--fw-color-primary-default);
    margin: 0px;
  }

  &.rounded {
    border-radius: 50%;
  }

  &:disabled {
    background: var(--fw-color-primary-washed);
    border-color: var(--fw-color-primary-washed);
  }
}

.circle {
  border-radius: 50%;
  width: 24px;
  height: 24px;
}

@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.titleContainer {
  display: flex;
  align-items: flex-start;

  .title {
    @include heading5($font-weight-bold, $ds-dark-2);
    margin: 0 10px 0 0;

    &.defaultTitle {
      opacity: 0.5;
    }
  }

  .labels {
    display: flex;
    align-items: flex-start;

    > * {
      margin-left: 0.7rem;
    }
  }
}

@use 'src/styles/variables' as *;

.fileContainer {
  @include fw-elevation-light-top-4();
  max-height: 70vh;
  overflow-y: auto;
  border-radius: 12px;
  background-color: $shadeWhite;
  padding: 24px;

  &.focused {
    border-left: 4px solid $primaryDefault;
  }
}

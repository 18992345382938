.tagContainer {
  display: flex;
  flex-direction: row;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 8px;
  }

  .tagText {
    flex-wrap: nowrap;
  }
}

@use 'src/styles/variables' as *;

.container {
  width: 15rem;
}

.displayValueWrapper {
  display: flex;
}

.documentStepValue {
  display: inline;
}

.iconWrapper {
  margin-top: 0.25rem;
  margin-right: 0.5rem;
}

.tooltipWrapperContent {
  overflow-wrap: normal;
  word-break: normal;
}

.tooltipChildWrapper {
  max-width: 11rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.iconSelectWrapper {
  margin-top: 0.5rem;
  margin-left: 0.75rem;
}

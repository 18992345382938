@use 'src/styles/legacy/variables' as *;
@use 'src/styles/legacy/variables' as *;

$nano: 32px;
$micro: 40px;
$normal: 46px;
$macro: 64px;

$dark-background: grey;
$dark-background-hovered: rgb(141, 140, 140);

$light-background: white;
$light-background-hovered: rgb(241, 241, 241);

$transparent-background: transparent;
$transparent-background-hovered: transparentize($light-background, 0.7);

$primary-background: #4a90e2;
$primary-background-hovered: #555878;

$secondary-background: #099a84;
$secondary-background-hovered: #555878;

@mixin button-size($size) {
  height: $size;
  width: $size;
}

.button {
  position: relative;
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: none;
  transition: 0.2s ease all;
}

.dark {
  background-color: $dark-background;
  &:hover {
    background-color: $dark-background-hovered;
  }
}

.light {
  background-color: $light-background;
  &:hover {
    background-color: $light-background-hovered;
  }
}

.transparent {
  background-color: $transparent-background;
  border: 1px solid var(--fw-color-shades-shade4);

  &.noBorder {
    border: none;
  }
  &:hover {
    background-color: $transparent-background-hovered;
  }
}

.transparent-dark {
  background-color: $transparent-background;
  border: 1px solid $light-background;

  &.noBorder {
    border: none;
  }
  &:hover {
    background-color: $transparent-background-hovered;
  }
}

.primary {
  background-color: $primary-background;
  &:hover {
    background-color: $primary-background-hovered;
  }
}
.secondary {
  background-color: $secondary-background;
  &:hover {
    background-color: $secondary-background-hovered;
  }
}

.nano {
  @include button-size($nano);
}

.micro {
  @include button-size($micro);
}

.normal {
  @include button-size($normal);
}

.macro {
  @include button-size($macro);
}

.elevation1 {
  @include elevation(1);
}

.elevation2 {
  @include elevation(2);
}

.elevation3 {
  @include elevation(3);
}

@keyframes expand {
  0% {
    height: 0;
    overflow: hidden;
  }

  99% {
    overflow: hidden;
  }

  100% {
    overflow: visible;
    height: 80px;
  }
}

@keyframes collapse {
  0% {
    height: 80px;
    overflow: visible;
  }

  1% {
    overflow: hidden;
  }

  100% {
    height: 0;
  }
}

.container {
  overflow: hidden;
  height: 0;

  &.opened {
    animation: expand 0.5s forwards;
  }

  &.closed {
    animation: collapse 0.5s forwards;
  }

  .content {
    padding: 1rem 1.5rem;
  }
}

@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--fw-color-shades-white);

  @media #{$not-mobile} {
    width: 976px;
    margin: auto;
    @include elevation(3);
  }
}

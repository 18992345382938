@use 'src/styles/variables' as *;

.pageContent {
  min-height: calc(100vh - var(--navbar-height));
  display: flex;
  width: 100%;

  .listAndFiltersContainer {
    z-index: 3;
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 25vw;
    box-shadow: 9px 0px 17px 0px $shadowDark;
    height: calc(100vh - var(--navbar-height));
    background-color: $shadeWhite;

    .filters {
      display: grid;
      align-items: center;
      width: 100%;
      padding: 24px;
      background-color: $shade8;
    }

    .listTitle {
      background-color: $shade2;
      padding: 15px;

      &::-webkit-scrollbar {
        display: none;
      }

      h3 {
        display: flex;
        align-items: center;
        word-break: break-all;
        color: white;
        height: 100%;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  .eventDetails {
    width: 100%;
    margin-left: 25vw;

    &.eventWithoutDocs {
      padding: 10vw;
    }
  }
}

@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.labelContainer {
  border-radius: 4px;
  padding: 4px;

  .text {
    color: $ds-grey-blue;
    font-size: toRem(16px);
  }
  &.dark {
    background-color: $ds-dark-3;

    .text {
      color: white;
    }
  }

  &.light {
    border: solid 1px $ds-light-6;
  }

  &.warning {
    background-color: $color-warning;
    .text {
      color: white;
    }
  }
}

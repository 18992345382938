@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.container {
  height: 100%;
  width: 100%;
  overflow-y: scroll;

  .sectionTitle {
    @include typo-base();
    @include typo-input-value();
    @include typo-input-size-big();
    color: var(--fw-color-shades-shade5);
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 28px;
  }

  .inputContainer {
    & > *:first-child {
      margin-top: 16px;
    }

    & > *:not(:first-child) {
      margin-top: 16px;
    }
  }
}

.form {
  height: 100%;
  width: 100%;
}

.errorMessage {
  height: toRem(11px);
  font-size: toRem(10px);
  color: var(--fw-color-status-invalid);
}

.Icon-module_icon__J5RH5 {
  fill: currentColor;
}
.ToolbarButton-module_button__NNb4- {
  cursor: pointer;
  height: 24px;
  min-width: 24px;
  padding: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 2px;
  background: transparent;
  color: rgb(18, 42, 89);
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: inherit;
}
.ToolbarButton-module_button__NNb4- .ToolbarButton-module_label__Usy-B {
  font-size: 1rem;
}
.ToolbarButton-module_button__NNb4-.ToolbarButton-module_shrink__XyZtO {
  height: 22px;
  min-width: 22px;
}
.ToolbarButton-module_button__NNb4-:disabled {
  color: rgb(168, 187, 224);
  cursor: not-allowed;
}
.ToolbarButton-module_button__NNb4-:not(:disabled):focus-visible, .ToolbarButton-module_button__NNb4-:not(:disabled):hover {
  background-color: rgba(208, 221, 245, 0.35);
  outline: none;
}
.ToolbarButton-module_button__NNb4-:not(:disabled).ToolbarButton-module_active__FxBGI {
  background-color: rgba(208, 221, 245, 0.75);
}
.ToolbarButton-module_button__NNb4- .ToolbarButton-module_label__Usy-B {
  font-size: 16px;
  margin-left: 4px;
  margin-right: 2px;
}
.ToolbarContainer-module_toolbar__wkKMP {
  position: sticky;
  z-index: 1;
  border-radius: 8px;
  box-shadow: 0px 4px 20px 8px rgba(6, 2, 25, 0.08);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;
  width: fit-content;
  padding: 8px 16px;
  font-size: 1.25rem;
  background-color: rgb(255, 255, 255);
  font-family: "Montserrat", sans-serif;
}
.ToolbarContainer-module_toolbar__wkKMP [data-separator] + [data-separator],
.ToolbarContainer-module_toolbar__wkKMP [data-separator]:first-child,
.ToolbarContainer-module_toolbar__wkKMP [data-separator]:last-child {
  display: none;
}
.ToolbarContainer-module_toolbar__wkKMP.ToolbarContainer-module_compact__033I1 {
  padding: 8px;
  gap: 2px;
}
.ToolbarSelect-module_listbox__1XkZT {
  position: relative;
  height: 24px;
  width: 100%;
}

.ToolbarSelect-module_button__c5XfH {
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  height: 100%;
  width: 100%;
  padding: 0 2px;
  text-align: left;
  background-color: transparent;
  cursor: pointer;
  border: none;
  border-radius: 2px;
  color: rgb(18, 42, 89);
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 16px;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ToolbarSelect-module_button__c5XfH:focus-visible, .ToolbarSelect-module_button__c5XfH[data-active], .ToolbarSelect-module_button__c5XfH:hover {
  background-color: rgba(208, 221, 245, 0.35);
  outline: none;
}
.ToolbarSelect-module_button__c5XfH .ToolbarSelect-module_label__MTcGB {
  flex-grow: 1;
  padding-left: 2px;
  padding-right: 18px;
}
.ToolbarSelect-module_button__c5XfH .ToolbarSelect-module_icon__iWgb2 {
  height: 1.25rem;
  font-size: 1.25rem;
}
.ToolbarSelect-module_button__c5XfH .ToolbarSelect-module_placeholder__DTeo1 {
  color: rgb(168, 187, 224);
}
.ToolbarSelect-module_button__c5XfH .ToolbarSelect-module_caret__WLmI7 {
  position: absolute;
  font-size: 24px;
  inset: auto 4px auto auto;
  line-height: 0;
}

.ToolbarSelect-module_options__Q-oi7 {
  position: absolute;
  inset: auto 0;
  padding: 2px;
  margin: 4px 0 0;
  width: max-content;
  font-size: 1rem;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: rgb(18, 42, 89);
  box-shadow: 0px 4px 20px 8px rgba(6, 2, 25, 0.08);
}
.ToolbarSelect-module_options__Q-oi7:focus-visible {
  box-shadow: 0px 10px 32px 8px rgba(6, 2, 25, 0.1);
  outline: none;
}

.ToolbarSelect-module_option__T-ZJw {
  list-style-type: none;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
}
.ToolbarSelect-module_option__T-ZJw[data-focus] {
  background-color: rgb(217, 235, 255);
}
.ToolbarSelect-module_option__T-ZJw[data-selected] {
  background-color: rgb(69, 143, 230);
  color: rgb(255, 255, 255);
}

.ToolbarSelect-module_transition__0hIRF {
  transition: opacity 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ToolbarSelect-module_transition__0hIRF.ToolbarSelect-module_from__ThqSQ {
  opacity: 1;
}
.ToolbarSelect-module_transition__0hIRF.ToolbarSelect-module_to__XPXQs {
  opacity: 0;
}
.ToolbarSeparator-module_separator__3-ieT {
  height: 24px;
  margin: 0 4px;
  width: 1px;
  background-color: rgb(230, 237, 241);
  align-self: center;
  justify-self: center;
}
.ToolbarSeparator-module_separator__3-ieT.ToolbarSeparator-module_compact__QEWn5 {
  margin: 0 2px;
}
.ToolbarInput-module_container__aiI27 {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  overflow: hidden;
  border-radius: 2px;
  height: 22px;
}
.ToolbarInput-module_container__aiI27:focus-visible, .ToolbarInput-module_container__aiI27:focus-within, .ToolbarInput-module_container__aiI27:hover {
  background-color: rgba(208, 221, 245, 0.2);
}

.ToolbarInput-module_input__042wg {
  background: transparent;
  width: 100%;
  height: 100%;
  padding: 0;
  color: rgb(18, 42, 89);
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  outline: none;
  border-radius: 2px;
  border: none;
}
.ToolbarInput-module_input__042wg:focus-visible, .ToolbarInput-module_input__042wg:focus-within, .ToolbarInput-module_input__042wg:hover {
  background-color: rgba(208, 221, 245, 0.35);
}
.ToolbarInput-module_input__042wg[type=number] {
  /* Firefox */
  -moz-appearance: textfield;
}
.ToolbarInput-module_input__042wg[type=number]::-webkit-outer-spin-button, .ToolbarInput-module_input__042wg[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ToolbarColorPalette-module_listbox__mKauo {
  position: relative;
}

.ToolbarColorPalette-module_button__1jGPQ {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  padding: 1.5px;
  border: none;
  border-radius: 2px;
  background: transparent;
  color: rgb(18, 42, 89);
}
.ToolbarColorPalette-module_button__1jGPQ:not(:disabled):focus-visible, .ToolbarColorPalette-module_button__1jGPQ:not(:disabled):hover, .ToolbarColorPalette-module_button__1jGPQ:not(:disabled)[data-active] {
  background-color: rgba(77, 101, 153, 0.15);
  outline: none;
}

.ToolbarColorPalette-module_caret__7QgOr {
  width: 18px;
  height: 18px;
  background-color: currentColor;
  border: 2px solid transparent;
  border-radius: 4px;
}

.ToolbarColorPalette-module_options__iPGo4 {
  position: absolute;
  inset: auto 0;
  padding: 2px;
  margin: 4px 0 0;
  width: max-content;
  font-size: 1rem;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: rgb(18, 42, 89);
  box-shadow: 0px 4px 20px 8px rgba(6, 2, 25, 0.08);
  padding: 6px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.ToolbarColorPalette-module_options__iPGo4:focus-visible {
  box-shadow: 0px 10px 32px 8px rgba(6, 2, 25, 0.1);
  outline: none;
}

.ToolbarColorPalette-module_option__yA619 {
  list-style-type: none;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
  padding: 4px;
}
.ToolbarColorPalette-module_option__yA619[data-focus] {
  background-color: rgb(217, 235, 255);
}
.ToolbarColorPalette-module_option__yA619[data-selected] > .ToolbarColorPalette-module_caret__7QgOr {
  border-color: rgb(69, 143, 230);
}

.ToolbarColorPalette-module_transition__k1-Ak {
  transition: opacity 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ToolbarColorPalette-module_transition__k1-Ak.ToolbarColorPalette-module_from__--nua {
  opacity: 1;
}
.ToolbarColorPalette-module_transition__k1-Ak.ToolbarColorPalette-module_to__1oFzr {
  opacity: 0;
}
.FontGroup-module_headingSelect__7hmVy {
  width: 6rem;
}

.FontGroup-module_fontFamilySelect__jREst {
  width: 8rem;
}

.FontGroup-module_fontSizeSelect__LHgi7 {
  width: 5rem;
}
.HeadingSelect-module_headingOption__5Lcp- {
  margin: 0;
}
.UndoGroup-module_container__bA0j- {
  display: flex;
  flex-wrap: nowrap;
}
.SpecialContentGroup-module_button__clRE8 {
  font-size: 1.125rem;
}
.HeightGroup-module_select__fh72X {
  width: auto;
}
.Toolbar-module_toolbar__jXPuT {
  position: sticky;
  z-index: 1;
  top: 0;
  padding: 24px 8px 0;
  display: flex;
  justify-content: center;
}
.Toolbar-module_toolbar__jXPuT.Toolbar-module_compact__8sKCN {
  padding: 8px 4px 0;
}
.ResizeHandle-module_resize-handle__JnGEi {
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s ease;
  cursor: col-resize;
  border-radius: 6px;
  width: 0;
  height: 32px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}
.ResizeHandle-module_resize-handle__JnGEi.ResizeHandle-module_left__HNBQ0 {
  left: 2px;
  border-left: 6px solid rgb(36, 102, 178);
}
.ResizeHandle-module_resize-handle__JnGEi.ResizeHandle-module_right__DCrDE {
  right: 2px;
  border-right: 6px solid rgb(36, 102, 178);
}

*:hover > .ResizeHandle-module_resize-handle__JnGEi {
  opacity: 1;
}
.ImageResize-module_image_line__wOSIQ.ImageResize-module_float_left__CjyYt {
  float: left;
  padding-right: 8px;
}
.ImageResize-module_image_line__wOSIQ.ImageResize-module_float_right__szf5v {
  float: right;
  padding-left: 8px;
}
.ImageResize-module_image_line__wOSIQ.ImageResize-module_align_left__qX0Iq {
  text-align: left;
}
.ImageResize-module_image_line__wOSIQ.ImageResize-module_align_center__FTd-y {
  text-align: center;
}
.ImageResize-module_image_line__wOSIQ.ImageResize-module_align_right__Q6g2a {
  text-align: right;
}

.ImageResize-module_image_resizer__X5UUc {
  display: inline-flex;
  position: relative;
  flex-grow: 0;
}

.ImageResize-module_image__YX4O- {
  max-width: 100%;
  border: 1px transparent solid;
}
.ImageResize-module_image__YX4O-.ImageResize-module_focused__0Q41T {
  border-color: lightgray;
}

.ImageResize-module_error__LrLSL {
  background: rgb(230, 69, 82);
  color: rgb(255, 255, 255);
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 600;
}
@media print {
  .ImageResize-module_error__LrLSL {
    display: none;
  }
}
@charset "UTF-8";
.tiptap *[data-indent] {
  /* 
      Génère les sélecteurs CSS correspondant à chaque niveau d'indentation. Si attr() était pleinement supportée, l'équivalent serait
      --indent-padding: calc( 3rem * attr(data-indent rem) );

      /!\ Le niveau max doit être supérieur ou égal au niveau max spécifié dans la configuration du plugin
    */
}
.tiptap *[data-indent][data-indent="1"] {
  --indent-padding: calc(3rem * 1);
}
.tiptap *[data-indent][data-indent="2"] {
  --indent-padding: calc(3rem * 2);
}
.tiptap *[data-indent][data-indent="3"] {
  --indent-padding: calc(3rem * 3);
}
.tiptap *[data-indent][data-indent="4"] {
  --indent-padding: calc(3rem * 4);
}
.tiptap *[data-indent][data-indent="5"] {
  --indent-padding: calc(3rem * 5);
}
.tiptap *[data-indent][data-indent="6"] {
  --indent-padding: calc(3rem * 6);
}
.tiptap *[data-indent][data-indent="7"] {
  --indent-padding: calc(3rem * 7);
}
.tiptap *[data-indent][data-indent="8"] {
  --indent-padding: calc(3rem * 8);
}
.tiptap *[data-indent][style*="text-align: right"] {
  padding-right: var(--indent-padding);
}
.tiptap *[data-indent][style*="text-align: left"], .tiptap *[data-indent]:not([style*=text-align]) {
  padding-left: var(--indent-padding);
}
.tiptap div[role=pagebreak] {
  display: block;
  position: relative;
  break-after: page;
}
@media not print {
  .tiptap.with-ui div[role=pagebreak] {
    text-align: center;
  }
  .tiptap.with-ui div[role=pagebreak]::before {
    position: absolute;
    display: block;
    content: "";
    height: 1px;
    width: 100%;
    top: calc(50% - 1px);
    background-color: rgb(18, 42, 89);
  }
  .tiptap.with-ui div[role=pagebreak]::after {
    display: inline-block;
    content: "Saut de page";
    color: rgb(18, 42, 89);
    padding: 0 8px;
    background-color: white;
    position: relative;
  }
}
.tiptap table {
  border: 1px solid rgb(18, 42, 89);
  border-collapse: collapse;
  overflow: hidden;
  table-layout: fixed;
}
.tiptap table th {
  background-color: rgb(217, 235, 255);
}
.tiptap table th,
.tiptap table td {
  border: 1px solid rgb(18, 42, 89);
  min-width: 10px;
  position: relative;
}
.tiptap table p {
  margin: 0;
}
.tiptap table .column-resize-handle {
  background-color: rgb(69, 143, 229);
  inset: 0 -2px -2px auto;
  position: absolute;
  pointer-events: none;
  width: 4px;
}
.tiptap table .selectedCell:after {
  background: rgba(34, 45, 66, 0.25);
  content: "";
  inset: 0;
  pointer-events: none;
  position: absolute;
  z-index: 2;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
.tiptap.with-ui .VariableWrapper-module_container__KkO0K {
  --elfe-variable-color: #3273bf;
  --elfe-variable-background: #dcecff;
  border: 1px solid transparent;
  border-radius: 6px;
  color: var(--elfe-variable-color);
  background-color: var(--elfe-variable-background);
  cursor: pointer;
}
.tiptap.with-ui .VariableWrapper-module_container__KkO0K:focus-within {
  border-color: var(--elfe-variable-color);
}
.tiptap.with-ui .VariableWrapper-module_container__KkO0K.VariableWrapper-module_state-fallback__vCSyv {
  --elfe-variable-color: #ff2525;
}
.tiptap.with-ui .VariableWrapper-module_container__KkO0K.VariableWrapper-module_theme-stale__fwY3Y {
  --elfe-variable-background: rgba(228, 163, 73, 0.3);
}
.tiptap.with-ui .VariableWrapper-module_container__KkO0K.VariableWrapper-module_disabled__8fnoj {
  cursor: not-allowed;
}

.tiptap:not(.with-ui) .VariableWrapper-module_state-fallback__vCSyv {
  display: none;
}

.VariableWrapper-module_container__KkO0K.VariableWrapper-module_inline__qfxbF {
  display: inline;
}
.VariableWrapper-module_container__KkO0K.VariableWrapper-module_inline-block__kAs-6 {
  display: inline-block;
  width: fit-content;
}
.VariableWrapper-module_container__KkO0K.VariableWrapper-module_block__DpFiH {
  display: block;
}
.VariableWrapper-module_container__KkO0K.VariableWrapper-module_block__DpFiH ul,
.VariableWrapper-module_container__KkO0K.VariableWrapper-module_block__DpFiH ol {
  margin: 4px 4px 4px auto;
}
.VariableWrapper-module_container__KkO0K:empty {
  display: none;
}

.VariableWrapper-module_variable__DQxfb.VariableWrapper-module_variable-inline-block__4jErs {
  display: inline-block;
}
.VariableWrapper-module_variable__DQxfb.VariableWrapper-module_variable-inline__di99D {
  display: inline;
}
.VariableWrapper-module_variable__DQxfb.VariableWrapper-module_variale-block__GU2rT {
  display: block;
}
.VariableMenu-module_menu__7K8MT {
  padding: 1rem;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  max-height: 430px;
  max-width: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0px 4px 20px 8px rgba(6, 2, 25, 0.08);
}
.tiptap .variable-center {
  display: inline-flex;
  text-align: center;
  flex-direction: column;
}
.elfe-module_elfe-base__6pSvZ .tiptap {
  font-family: var(--elfe-default-font-family);
}
.elfe-module_elfe-base__6pSvZ .tiptap p,
.elfe-module_elfe-base__6pSvZ .tiptap h1,
.elfe-module_elfe-base__6pSvZ .tiptap h2,
.elfe-module_elfe-base__6pSvZ .tiptap h3,
.elfe-module_elfe-base__6pSvZ .tiptap h4,
.elfe-module_elfe-base__6pSvZ .tiptap h5,
.elfe-module_elfe-base__6pSvZ .tiptap h6 {
  margin-block-start: 0;
  margin-block-end: 1rem;
}
.elfe-module_elfe-base__6pSvZ .tiptap > :last-child {
  margin-block-end: 0;
}
.elfe-module_elfe-base__6pSvZ li > p,
.elfe-module_elfe-base__6pSvZ li h1,
.elfe-module_elfe-base__6pSvZ li h2,
.elfe-module_elfe-base__6pSvZ li h3,
.elfe-module_elfe-base__6pSvZ li h4,
.elfe-module_elfe-base__6pSvZ li h5,
.elfe-module_elfe-base__6pSvZ li h6 {
  margin-block-end: 0;
}
.elfe-module_elfe-base__6pSvZ .ProseMirror:focus {
  outline: none;
}
.elfe-module_elfe-base__6pSvZ.elfe-module_no-paragraph-space__3ITq4 .tiptap p {
  margin-block: 0;
}

.elfe-module_elfe-container__XgEL2 {
  min-height: 100px;
  height: 100%;
  background: rgb(255, 255, 255);
}
.elfe-module_elfe-container__XgEL2 .elfe-module_editor__-Rcvh {
  height: 100%;
}
.elfe-module_elfe-container__XgEL2.elfe-module_rounded__-x2wp {
  border-radius: 12px;
}
.elfe-module_elfe-container__XgEL2.elfe-module_angled__vWhH4 {
  border-radius: 0;
}
.elfe-module_elfe-container__XgEL2 .tiptap {
  height: 100%;
  padding: 2rem;
}
.elfe-module_elfe-container__XgEL2 .tiptap[contenteditable=false] {
  cursor: not-allowed;
}
.elfe-module_elfe-container__XgEL2 .tiptap p.is-editor-empty:first-child::before {
  color: rgba(0, 0, 0, 0.3);
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
.ErrorBoundaryContent-module_card__Ld4pq {
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  gap: 16px;
  padding: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: rgb(230, 69, 82);
  background-color: rgba(230, 69, 82, 0.1);
}

.ErrorBoundaryContent-module_message__LVzp4 {
  margin: 0;
}

.ErrorBoundaryContent-module_button__Po4F- {
  appearance: none;
  padding: 8px;
  border-radius: 4px;
  font-weight: 600;
  color: white;
  border: none;
  transition: background-color 0.2s ease-in-out;
}
.ErrorBoundaryContent-module_button__Po4F-.ErrorBoundaryContent-module_red__-e3qf {
  background-color: rgba(230, 69, 82, 0.8);
}
.ErrorBoundaryContent-module_button__Po4F-.ErrorBoundaryContent-module_red__-e3qf:hover {
  background-color: #e64552;
}
.ErrorBoundaryContent-module_button__Po4F-.ErrorBoundaryContent-module_orange__CPIC1 {
  background-color: rgba(242, 186, 73, 0.8);
}
.ErrorBoundaryContent-module_button__Po4F-.ErrorBoundaryContent-module_orange__CPIC1:hover {
  background-color: #f2ba49;
}
@import '../../../styles/variables/media-queries';

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.editor {
  width: 100%;
  min-height: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.2);

  // Permet de selectionner automatiquement une variable slate pour simplifier le copier coller
  [data-slate-void='true'] {
    user-select: all;
  }

  @media #{$not-mobile} {
    max-width: 1000px;
  }
}

@use 'src/styles/variables' as *;

.container {
  padding: 0.75rem;
  border-color: $shade6;
  border-style: solid;
  border-width: 1px;
  border-radius: 0.375rem;
  align-items: center;
  display: flex;

  &.warn {
    background-color: $labelTransparentOrange;
  }

  &.error {
    background-color: $labelTransparentRed;
  }

  &.success {
    background-color: $labelTransparentGreen;
  }

  .icon {
    align-self: flex-start;
    margin-right: 0.75rem;
  }

  .message {
    word-break: break-word;
    color: $shade4;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-family: Montserrat, sans-serrif;
    fill: currentColor;
  }
}

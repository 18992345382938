.form {
  & > * {
    margin-bottom: 15px;
  }
  .passwordActions {
    display: flex;
    flex-direction: row;

    & > *:not(:last-child) {
      margin-right: 8px;
    }
  }
}

@use 'src/styles/variables' as *;

@mixin specificContainer($width, $height) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $width;
  height: $height;
  background-color: $shadeWhite;
}

.loaderContainer {
  @include specificContainer(400px, 600px);
}

.noItemsContainer {
  @include specificContainer(500px, 300px);
  flex-direction: column;
  padding: 20px 30px 30px;

  > span {
    color: $shade2;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
  }
}

.MedicalEventsDocumentsListContainer {
  display: flex;
  flex-direction: column;
  min-width: 30vw;
  max-width: 35vw;
  background-color: $shadeWhite;
  overflow-y: auto;

  @media #{$small-screen} {
    min-width: 350px;
  }

  .medicalEventContainer {
    border-top: 1px solid $shade7;
    padding: 0 1rem;
    padding-bottom: 1.5rem;

    .medicalEventHeaderContainer {
      display: flex;
      align-items: center;
      gap: 8px;
      margin: 16px 0;

      .medicalEventDescription {
        display: flex;
        align-items: center;
        width: 100%;

        h4 {
          @include ellipsis();
          font-size: 16px;
          font-weight: 600;
        }

        .medicalEventDate {
          color: $shade3;
          font-size: 12px;
          font-weight: 500;
          margin-left: auto;
        }
      }
    }

    .documentItems {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .documentItemContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        gap: 10px;
        background-color: $shade7;
        padding: 12px 16px;
        border-radius: 4px;
        gap: 10px;

        .documentItemDescription {
          display: flex;
          align-items: center;
          width: 100%;
          gap: 10px;

          .documentItemName {
            @include ellipsis();
            font-size: 14px;
            font-weight: 600;
            color: $shade2;
          }
        }

        &.focused {
          background-color: $primaryDefault;

          .documentItemDescription {
            width: 92%;

            .documentItemName {
              color: $shadeWhite;
            }
          }
        }
      }
    }
  }
}

// ! DEPRECATED ! Utiliser les couleurs présentes dans ./_colors_v3

/* BRAND COLORS */
$color-brand-primary: #4a90e2;
$color-brand-secondary: #0cc1a5;
$color-brand-gradient: linear-gradient(
  -90deg,
  $color-brand-primary 0%,
  lighten($color-brand-primary, 20%) 100%
);

/* MEANINGFUL COLORS */
$color-success: #099a84;
$color-error: #d45858;
$color-warning: #e4a349;
$color-info: #3b73b4;

/** MEDICAL EVENT COLOR */
$medical-event-green-color: #099a84;
$medical-event-blue-color: #3b73b4;
$medical-event-defaut-color: #445370;

/* CONTENT COLORS */
// dark
$color-content-dark-1: #555878;
$color-content-dark-2: #1b2d43;
$color-content-dark-3: rgba($color-content-dark-1, 0.26); // #d3d4e1 charte
// light
$color-content-light-1: #fff;
$color-content-light-2: rgba($color-content-light-1, 0.7);
$color-content-light-3: rgba($color-content-light-1, 0.25);

/* BACKGROUND COLORS */
$color-background-primary: $color-brand-primary;
// dark
$color-background-dark-1: #273142;
$color-background-dark-2: #323f56;
$color-background-dark-3: #3a4860;
// light
$color-background-light-1: #ffffff;
$color-background-light-2: #fafcfe;
$color-background-light-3: #d3d4e1;

// blue
$color-background-blue-1: #fafcfe;
$color-background-blue-2: #eff5fc;
$color-background-blue-3: #cedbeb;
$color-background-blue-4: #bfd0e5;
$color-background-blue-5: #afc4db;
$color-background-blue-6: #a1b8d4;

/* BORDER COLORS */
// dark
$color-border-dark-1: $color-content-dark-1;
$color-border-dark-2: $color-content-dark-2;
// light
$color-border-light-1: $color-background-light-1;
$color-border-light-2: rgba($color-content-light-1, 0.25);

/* ************* */
/* DESIGN-SYSTEM */
/* ************* */
$ds-white: #ffffff;

$ds-primary-default: #4a90e2;
$ds-primary-dark: #3b73b4;
$ds-primary-darker: #4d6599;
$ds-primary-light: #6ea6e7;
$ds-primary-washed: #dbe9f9;

$ds-secondary-dark: #099a84;
$ds-secondary-default: #0cc1a5;
$ds-secondary-light: #55d3c0;
$ds-secondary-washed: #cef3ed;

$ds-dark-1: var(--fw-color-shades-shade1);
$ds-dark-1-transparent: rgba($ds-dark-1, 0.6);
$ds-dark-2: var(--fw-color-shades-shade2);
$ds-dark-3: var(--fw-color-shades-shade3);
$ds-dark-4: var(--fw-color-shades-shade4);

$ds-light-5: var(--fw-color-shades-shade5);
$ds-light-6: var(--fw-color-shades-shade6);
$ds-light-7: var(--fw-color-shades-shade7);
$ds-light-8: var(--fw-color-shades-shade8);
$ds-light-9: var(--fw-color-shades-white);

$ds-grey-blue: #6b7a99;
$ds-dimmer-dark: var(--fw-color-dimmer-dark);

$ds-error: #e64552;
$ds-warning: #e4a349;

$ds-tag--visibility: $ds-light-9;
$ds-tag-text-visibility: $ds-dark-4;

$ds-tag--00: $ds-light-7;
$ds-tag-text-00: $ds-dark-4;
$ds-tag--01: #eeeef1;
$ds-tag-text-01: #555878;
$ds-tag--02: #f3f0fc;
$ds-tag-text-02: #886ce6;
$ds-tag--03: #ecf3fc;
$ds-tag-text-03: #4a90e2;
$ds-tag--04: #ebf1f7;
$ds-tag-text-04: #3b73b4;
$ds-tag--05: #e6f4f2;
$ds-tag-text-05: #099a84;
$ds-tag--06: #f8f7e8;
$ds-tag-text-06: #bfb219;
$ds-tag--07: #fcf5ec;
$ds-tag-text-07: #e4a349;
$ds-tag--08: #faeeee;
$ds-tag-text-08: #d45858;
$ds-tag--09: #f4e6e6;
$ds-tag-text-09: #9a0909;
$ds-tag--10: #f3eee9;
$ds-tag-text-10: #8b572a;

:export {
  // contents
  brandPrimary: $color-brand-primary;
  brandSecondary: $color-brand-secondary;
  success: $color-success;
  error: $color-error;
  warning: $color-warning;
  info: $color-info;
  contentDark1: $color-content-dark-1;
  contentDark2: $color-content-dark-2;
  contentDark3: $color-content-dark-3;
  contentLight1: $color-content-light-1;
  contentLight2: $color-content-light-2;
  contentLight3: $color-content-light-3;
  // backgrounds
  backgroundPrimary: $color-background-primary;
  backgroundLight1: $color-background-light-1;
  backgroundLight2: $color-background-light-2;
  backgroundLight3: $color-background-light-3;
  backgroundDark1: $color-background-dark-1;
  backgroundDark2: $color-background-dark-2;
  backgroundDark3: $color-background-dark-3;
  backgroundBlue1: $color-background-blue-1;
  backgroundBlue2: $color-background-blue-2;
  backgroundBlue3: $color-background-blue-3;
  backgroundBlue4: $color-background-blue-4;
  backgroundBlue5: $color-background-blue-5;
  backgroundBlue6: $color-background-blue-6;
  // Borders
  borderLight1: $color-border-light-1;
  borderLight2: $color-border-light-2;
  borderDark1: $color-border-dark-1;
  borderDark2: $color-border-dark-2;

  // Design system
  dsWhite: $ds-white;

  dsPrimaryDefault: $ds-primary-default;
  dsPrimaryDark: $ds-primary-dark;
  dsPrimaryLight: $ds-primary-light;
  dsPrimaryWashed: $ds-primary-washed;

  dsSecondaryDefault: $ds-secondary-default;
  dsSecondaryDark: $ds-secondary-dark;
  dsSecondaryLight: $ds-secondary-light;
  dsSecondaryWashed: $ds-secondary-washed;

  dsDark1: $ds-dark-1;
  dsDark2: $ds-dark-2;
  dsDark3: $ds-dark-3;
  dsDark4: $ds-dark-4;
  dsGreyBlue: $ds-grey-blue;

  dsLight5: $ds-light-5;
  dsLight6: $ds-light-6;
  dsLight7: $ds-light-7;
  dsLight8: $ds-light-8;
  dsLight9: $ds-light-9;

  dsGreyBlue: $ds-grey-blue;
  dsDimmerDark: $ds-dimmer-dark;

  // Semantic Tags
  dsTagVisibility: $ds-tag--visibility;
  dsTagTextVisibility: $ds-tag-text-visibility;
  // Tags
  dsTag00: $ds-tag--00;
  dsTagText00: $ds-tag-text-00;
  dsTag01: $ds-tag--01;
  dsTagText01: $ds-tag-text-01;
  dsTag02: $ds-tag--02;
  dsTagText02: $ds-tag-text-02;
  dsTag03: $ds-tag--03;
  dsTagText03: $ds-tag-text-03;
  dsTag04: $ds-tag--04;
  dsTagText04: $ds-tag-text-04;
  dsTag05: $ds-tag--05;
  dsTagText05: $ds-tag-text-05;
  dsTag06: $ds-tag--06;
  dsTagText06: $ds-tag-text-06;
  dsTag07: $ds-tag--07;
  dsTagText07: $ds-tag-text-07;
  dsTag08: $ds-tag--08;
  dsTagText08: $ds-tag-text-08;
  dsTag09: $ds-tag--09;
  dsTagText09: $ds-tag-text-09;
  dsTag10: $ds-tag--10;
  dsTagText10: $ds-tag-text-10;
}

@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.separator {
  height: 100%;
  width: 1px;
  background-color: var(--fw-color-shades-shade7);
}

.mobileAdd {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: $z-index-fixed-above;
}

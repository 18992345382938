@use 'src/styles/variables' as *;

$light: var(--fw-color-shades-white);

.container {
  z-index: $z-index-max;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  border-radius: 4px;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  position: relative;
  & > svg {
    margin-left: 15px;
  }
  .leftTriangle {
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-right: 7px solid $light;
    border-bottom: 6px solid transparent;
    z-index: $z-index-max;

    &.top {
      margin-top: 10px;
    }
    &.bottom {
      margin-top: auto;
      margin-bottom: 10px;
    }
  }
  .rightTriangle {
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-left: 7px solid $light;
    border-bottom: 6px solid transparent;

    &.top {
      margin-top: 10px;
    }
    &.bottom {
      margin-top: auto;
      margin-bottom: 10px;
    }
  }
  .menu {
    width: 100%;
    display: grid;
    grid-auto-flow: row;
    gap: 4px;
    border: solid 1px $light;
    border-radius: 4px;
    background-color: $light;
    padding: 4px;
    overflow-y: auto;
    @include fw-elevation-light-top-8();

    &.verticalScroll {
      max-height: 215px;
    }
  }
}

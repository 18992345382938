@use 'src/styles/variables' as *;

.documentItemContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  gap: 10px;
  background-color: $shade7;
  padding: 12px 16px;
  border-radius: 4px;
  gap: 10px;

  .documentItemDescription {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;

    .documentItemName {
      @include ellipsis();
      font-size: 14px;
      font-weight: 600;
      color: $shade2;
    }
  }

  &.focused {
    background-color: $primaryDefault;

    .documentItemDescription {
      width: 92%;

      .documentItemName {
        color: $shadeWhite;
      }
    }
  }
}

@use 'src/styles/legacy/variables' as *;

.button {
  border-radius: 9px;
  border: none;
  cursor: pointer;
  display: block;
  flex-grow: 1;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 500;
  height: 44px;
  outline: none;
  padding: 0px 15px;
  position: relative;
  text-align: center;
  transition: 0.2s ease all;
  &.disabled {
    cursor: not-allowed;
  }
}

.label {
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    margin-right: 10px;
  }
}

.primary {
  background-color: $ds-primary-default;
  color: $ds-white;
  &:hover:not(.disabled) {
    background-color: $color-content-dark-1;
  }
  &.disabled {
    color: $ds-light-6;
    background-color: lighten($ds-primary-default, 20%);
  }
}

.light {
  background-color: $ds-white;
  color: $color-content-dark-1;
  border: 2px solid $ds-light-5;
  &:hover:not(.disabled) {
    color: $ds-white;
    background-color: $ds-light-5;
  }
  &.disabled {
    color: $ds-light-5;
    background-color: $ds-light-6;
  }
}

.dark {
  background-color: $color-content-dark-1;
  color: $ds-white;
  &:hover:not(.disabled) {
    background-color: $ds-primary-default;
  }
  &.disabled {
    color: $ds-light-6;
    background-color: lighten($color-content-dark-1, 20%);
  }
}

.transparent {
  background-color: transparent;
  color: $color-content-dark-1;
  border: 2px solid $ds-light-6;
  &:hover:not(.disabled) {
    background-color: $color-content-dark-1;
    color: white;
    border: 2px solid $color-content-dark-1;
  }
  &.disabled {
    color: $ds-light-6;
  }
}

.warning {
  background-color: $color-warning;
  color: $ds-dark-2;
  &:hover:not(.disabled) {
    background-color: lighten($color-warning, 15%);
  }
  &.disabled {
    color: $ds-grey-blue;
  }
}

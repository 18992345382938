@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.column {
  display: grid;
  grid-auto-flow: row;
  align-items: start;
  gap: 1.5rem;
  width: 100%;
}

.input_layout {
  padding: 0.75rem;
  border-bottom: 1px solid $shade5;
}

.number_input_layout {
  padding-top: 0.5rem;
}

.pregnancy_label {
  color: $primaryDefault;
  cursor: pointer;

  &:hover {
    width: fit-content;
    border-bottom: 1px solid $primaryDefault;
  }
}

@import '../../../styles/variables/media-queries';

.deceasedAndMinorLabelContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 4px;
  padding-left: 20px;
  gap: 16px;

  @media #{$small-screen} {
    gap: 8px;
    flex-wrap: wrap;
    padding-left: 0px;
    margin-bottom: 12px;
    justify-content: start;
  }
}

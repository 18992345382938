@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.container {
  border-radius: 4px;
  padding: 16px;
  margin: 0px 24px 24px 24px;
  border: dashed 1px $ds-light-6;
}

.title {
  font-size: toRem(18px);
  font-weight: bold;
  letter-spacing: normal;
  color: $ds-dark-2;
  display: flex;
  align-items: center;

  .text {
    margin-left: 7px;
  }
}

.placeholders {
  display: flex;
  flex-direction: row;
  padding-top: 20px;

  > * {
    margin-right: 16px;
  }
}

@use 'src/styles/legacy/variables' as *;

.field {
  width: 100%;
  margin-bottom: 12px;
}
.errorMessage {
  height: 11px;
  font-size: 10px;
  color: var(--fw-color-status-invalid);
}

.inlineFields {
  display: flex;
  justify-content: space-between;
}
.zipcode {
  width: 100px;
  margin-right: 18px;
}
.city {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.suggestionItem {
  display: flex;
  align-items: center;
  padding: 1rem;
}
.addressPrediction {
  margin-left: 0.2rem;
  font-size: 0.9rem;
}

.inputSpacing {
  padding-top: 0.5rem;
}

@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  margin: 4px;
  cursor: pointer;

  .label {
    @include heading6($font-weight-normal, $ds-dark-2);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    padding: 8px;
    position: relative; // Pour le Ink
    border-radius: 4px;
    border: solid 1px $ds-light-5;
    min-height: toRem(52px);
    transition: background-color 0.2s;
  }
  .imageContainer {
    border-radius: 6px;
    border: solid 2px transparent;
    padding: 2px;
    margin-bottom: 4px;
  }
  .image {
    display: block;
    max-width: 150px;
    border-radius: 2px;
  }

  &.disabled {
    cursor: not-allowed;
  }
  &.optimist {
    cursor: wait;
  }

  &.activated:not(:active) {
    .label {
      background-color: $ds-primary-default;
      border-color: transparent;
      color: white;
    }
    .imageContainer {
      border-color: $ds-primary-default;
    }
    &.warning {
      .label {
        background-color: $color-warning;
        border-color: transparent;
        color: white;
      }
      .imageContainer {
        border-color: $color-warning;
      }
    }
    &.optimist {
      // Les élements en rendu optimist ne réagissent pas au clic
      pointer-events: none;

      .label {
        background-color: $ds-grey-blue;
        border-color: transparent;
        color: white;
      }
      .imageContainer {
        border-color: $ds-grey-blue;
      }
    }
  }

  &:not(.disabled):hover {
    .label {
      border-color: $ds-grey-blue;
    }
  }
  &:not(.disabled):active {
    .label {
      background-color: $ds-primary-light;
      border-color: transparent;
      color: white;
    }
    .imageContainer {
      border-color: $ds-primary-light;
    }
  }
}

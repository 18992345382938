.item {
  .titleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .isDefaultIcon {
    margin-left: 0.5rem;
  }
}

@import '../../../styles/variables/sizes';

.telephone1 {
  margin-right: 18px;
}

.submitButtonBox {
  display: flex;
  max-width: 240px;
}

.deprecated {
  margin-block-start: toRem(-12px);
  margin-block-end: toRem(12px);
  font-weight: 600;
  font-size: toRem(13px);
  color: var(--fw-color-status-invalid);
}

@use 'src/styles/legacy/variables' as *;

.content {
  overflow-y: auto;
  width: 46.25rem;
  background-color: $twGray50;
  min-height: 30rem;
}

.actions {
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  width: 100%;
  justify-content: end;
}

@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

/* heading 1 */
.heading1RegularDark {
  @include heading1($font-weight-normal, $ds-dark-2);
}
.heading1RegularLight {
  @include heading1($font-weight-normal, $ds-white);
}
.heading1RegularPrimary {
  @include heading1($font-weight-normal, $ds-primary-default);
}
.heading1RegularWarning {
  @include heading1($font-weight-normal, $ds-warning);
}
.heading1RegularSucces {
  @include heading1($font-weight-normal, $ds-secondary-default);
}
.heading1RegularMedium {
  @include heading1($font-weight-normal, $ds-grey-blue);
}
.heading1RegularError {
  @include heading1($font-weight-normal, $ds-error);
}

.heading1BoldDark {
  @include heading1($font-weight-bold, $ds-dark-2);
}
.heading1BoldLight {
  @include heading1($font-weight-bold, $ds-white);
}
.heading1BoldPrimary {
  @include heading1($font-weight-bold, $ds-primary-default);
}
.heading1BoldWarning {
  @include heading1($font-weight-bold, $ds-warning);
}
.heading1BoldSucces {
  @include heading1($font-weight-bold, $ds-secondary-default);
}
.heading1BoldMedium {
  @include heading1($font-weight-bold, $ds-grey-blue);
}
.heading1BoldError {
  @include heading1($font-weight-bold, $ds-error);
}

/* heading 2 */
.heading2RegularDark {
  @include heading2($font-weight-normal, $ds-dark-2);
}
.heading2RegularLight {
  @include heading2($font-weight-normal, $ds-white);
}
.heading2RegularPrimary {
  @include heading2($font-weight-normal, $ds-primary-default);
}
.heading2RegularWarning {
  @include heading2($font-weight-normal, $ds-warning);
}
.heading2RegularSucces {
  @include heading2($font-weight-normal, $ds-secondary-default);
}
.heading2RegularMedium {
  @include heading2($font-weight-normal, $ds-grey-blue);
}
.heading2RegularError {
  @include heading2($font-weight-normal, $ds-error);
}

.heading2BoldDark {
  @include heading2($font-weight-bold, $ds-dark-2);
}
.heading2BoldLight {
  @include heading2($font-weight-bold, $ds-white);
}
.heading2BoldPrimary {
  @include heading2($font-weight-bold, $ds-primary-default);
}
.heading2BoldWarning {
  @include heading2($font-weight-bold, $ds-warning);
}
.heading2BoldSucces {
  @include heading2($font-weight-bold, $ds-secondary-default);
}
.heading2BoldMedium {
  @include heading2($font-weight-bold, $ds-grey-blue);
}
.heading2BoldError {
  @include heading2($font-weight-bold, $ds-error);
}

/* heading 3 */
.heading3RegularDark {
  @include heading3($font-weight-normal, $ds-dark-2);
}
.heading3RegularLight {
  @include heading3($font-weight-normal, $ds-white);
}
.heading3RegularPrimary {
  @include heading3($font-weight-normal, $ds-primary-default);
}
.heading3RegularWarning {
  @include heading3($font-weight-normal, $ds-warning);
}
.heading3RegularSucces {
  @include heading3($font-weight-normal, $ds-secondary-default);
}
.heading3RegularMedium {
  @include heading3($font-weight-normal, $ds-grey-blue);
}
.heading3RegularError {
  @include heading3($font-weight-normal, $ds-error);
}

.heading3BoldDark {
  @include heading3($font-weight-bold, $ds-dark-2);
}
.heading3BoldLight {
  @include heading3($font-weight-bold, $ds-white);
}
.heading3BoldPrimary {
  @include heading3($font-weight-bold, $ds-primary-default);
}
.heading3BoldWarning {
  @include heading3($font-weight-bold, $ds-warning);
}
.heading3BoldSucces {
  @include heading3($font-weight-bold, $ds-secondary-default);
}
.heading3BoldMedium {
  @include heading3($font-weight-bold, $ds-grey-blue);
}
.heading3BoldError {
  @include heading3($font-weight-bold, $ds-error);
}

/* heading 4 */
.heading4RegularDark {
  @include heading4($font-weight-normal, $ds-dark-2);
}
.heading4RegularLight {
  @include heading4($font-weight-normal, $ds-white);
}
.heading4RegularPrimary {
  @include heading4($font-weight-normal, $ds-primary-default);
}
.heading4RegularWarning {
  @include heading4($font-weight-normal, $ds-warning);
}
.heading4RegularSucces {
  @include heading4($font-weight-normal, $ds-secondary-default);
}
.heading4RegularMedium {
  @include heading4($font-weight-normal, $ds-grey-blue);
}
.heading4RegularError {
  @include heading4($font-weight-normal, $ds-error);
}

.heading4BoldDark {
  @include heading4($font-weight-bold, $ds-dark-2);
}
.heading4BoldLight {
  @include heading4($font-weight-bold, $ds-white);
}
.heading4BoldPrimary {
  @include heading4($font-weight-bold, $ds-primary-default);
}
.heading4BoldWarning {
  @include heading4($font-weight-bold, $ds-warning);
}
.heading4BoldSucces {
  @include heading4($font-weight-bold, $ds-secondary-default);
}
.heading4BoldMedium {
  @include heading4($font-weight-bold, $ds-grey-blue);
}
.heading4BoldError {
  @include heading4($font-weight-bold, $ds-error);
}

/* heading 5 */
.heading5RegularDark {
  @include heading5($font-weight-normal, $ds-dark-2);
}
.heading5RegularLight {
  @include heading5($font-weight-normal, $ds-white);
}
.heading5RegularPrimary {
  @include heading5($font-weight-normal, $ds-primary-default);
}
.heading5RegularWarning {
  @include heading5($font-weight-normal, $ds-warning);
}
.heading5RegularSucces {
  @include heading5($font-weight-normal, $ds-secondary-default);
}
.heading5RegularMedium {
  @include heading5($font-weight-normal, $ds-grey-blue);
}
.heading5RegularError {
  @include heading5($font-weight-normal, $ds-error);
}

.heading5BoldDark {
  @include heading5($font-weight-bold, $ds-dark-2);
}
.heading5BoldLight {
  @include heading5($font-weight-bold, $ds-white);
}
.heading5BoldPrimary {
  @include heading5($font-weight-bold, $ds-primary-default);
}
.heading5BoldWarning {
  @include heading5($font-weight-bold, $ds-warning);
}
.heading5BoldSucces {
  @include heading5($font-weight-bold, $ds-secondary-default);
}
.heading5BoldMedium {
  @include heading5($font-weight-bold, $ds-grey-blue);
}
.heading5BoldError {
  @include heading5($font-weight-bold, $ds-error);
}

/* heading 6 */
.heading6RegularDark {
  @include heading6($font-weight-normal, $ds-dark-2);
}
.heading6RegularLight {
  @include heading6($font-weight-normal, $ds-white);
}
.heading6RegularPrimary {
  @include heading6($font-weight-normal, $ds-primary-default);
}
.heading6RegularWarning {
  @include heading6($font-weight-normal, $ds-warning);
}
.heading6RegularSucces {
  @include heading6($font-weight-normal, $ds-secondary-default);
}
.heading6RegularMedium {
  @include heading6($font-weight-normal, $ds-grey-blue);
}
.heading6RegularError {
  @include heading6($font-weight-normal, $ds-error);
}

.heading6BoldDark {
  @include heading6($font-weight-bold, $ds-dark-2);
}
.heading6BoldLight {
  @include heading6($font-weight-bold, $ds-white);
}
.heading6BoldPrimary {
  @include heading6($font-weight-bold, $ds-primary-default);
}
.heading6BoldWarning {
  @include heading6($font-weight-bold, $ds-warning);
}
.heading6BoldSucces {
  @include heading6($font-weight-bold, $ds-secondary-default);
}
.heading6BoldMedium {
  @include heading6($font-weight-bold, $ds-grey-blue);
}
.heading6BoldError {
  @include heading6($font-weight-bold, $ds-error);
}

@use 'src/styles/variables' as *;

.image {
  width: 34vw;
}

.pdf {
  width: 48vw;
}

.fileContainer {
  max-height: 70vh;
  overflow-y: auto;
  border-radius: 12px;
  background-color: $shadeWhite;

  &.focused {
    border-left: 4px solid $primaryDefault;
  }
}

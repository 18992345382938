@mixin fw-elevation-light-top-4 {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

@mixin fw-elevation-light-top-8 {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

@mixin fw-elevation-light-top-16 {
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.2);
}

@mixin fw-elevation-light-bottom-4 {
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.2);
}

@mixin fw-elevation-light-bottom-8 {
  box-shadow: 0px -8px 16px rgba(0, 0, 0, 0.2);
}

@mixin fw-elevation-light-bottom-16 {
  box-shadow: 0px -16px 32px rgba(0, 0, 0, 0.2);
}

@mixin fw-elevation-dark-top-4 {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.8);
}

@mixin fw-elevation-dark-top-8 {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.8);
}

@mixin fw-elevation-dark-top-16 {
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.8);
}

@mixin fw-elevation-dark-bottom-4 {
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.8);
}

@mixin fw-elevation-dark-bottom-8 {
  box-shadow: 0px -8px 16px rgba(0, 0, 0, 0.8);
}

@mixin fw-elevation-dark-bottom-16 {
  box-shadow: 0px -16px 32px rgba(0, 0, 0, 0.8);
}

@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.labeledCheckbox {
  @include typo-base;
  display: flex;
  width: 100%;
  align-items: center;
}

.label {
  @include typo-input-size-regular;
  margin-left: 8px;

  &.light {
    color: $color-content-light-2;
  }

  &.dark {
    color: $color-content-dark-2;
  }
}

@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

$transition-duration: 0.2s;

.container {
  position: relative;
  @include typo-base();
  width: 100%;

  .wrapper {
    display: flex;
    flex-direction: column;
    padding: 0.2rem;
    border: solid 1px $ds-light-5;
    border-radius: 4px;
  }
  &.invalid {
    border-color: $color-error !important;
  }

  &.valid {
    border-color: $color-success !important;
  }

  &.smallSize {
    @include typo-input-size-small();
  }

  &.normalSize {
    @include typo-input-size-regular();
  }

  &.bigSize {
    @include typo-input-size-big();
  }

  .input {
    @include typo-base();
    @include typo-input-value;
    appearance: none;
    border: none;
    background: transparent;
    font-size: inherit;
    height: 100%;
    width: 100%;
    resize: none;

    @media #{$small-screen} {
      font-size: 16px;
    }

    &:focus {
      outline: none;
    }
  }

  &.dark {
    .input {
      color: $color-content-dark-1;

      &:disabled {
        color: $color-content-dark-2;
        cursor: not-allowed;
      }
    }

    .label {
      color: $ds-dark-4;
    }
  }

  &.light {
    .input {
      color: $color-content-light-1;
      &::placeholder {
        color: $color-content-light-1;
      }

      &:disabled {
        color: $color-content-light-2;
        cursor: not-allowed;
      }
    }

    .label {
      color: $color-content-light-2;
    }
  }
}

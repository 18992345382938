.container {
  display: flex;
  flex-grow: 1;
}
.placeholder {
  margin: auto;
  max-width: 50%;
  text-align: center;
}
.image {
  margin-bottom: 2rem;
}
.svg {
  width: 141px;
  height: 120px;
  box-shadow: 0 18px 30px 0 #dfe3e9;
}
.button {
  display: inline-flex;
}

@use 'src/styles/legacy/variables' as *;

.container {
  padding: 16px 4px 16px 16px;
}

.questionTitleContainer {
  border-radius: 4px;
  background-color: $ds-light-7;
  font-size: toRem(18px);
  padding: 11px 16px 11px 8px;
  margin-bottom: 16px;
  display: flex;
}

.questionTitle {
  color: $ds-grey-blue;
}

.labels {
  margin: 4px 0 0 8px;
}

.answerTitle {
  font-size: toRem(18px);
  margin-top: 6px;
  font-weight: 600;
  color: $ds-dark-2;
}

@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.container {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}
.buttonContainer {
  display: flex;
  justify-content: space-between;
  width: 125px;
}
.hint {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @include typo-input-size-small();
  color: $ds-dark-4;
  width: 250px;

  @media #{$small-screen} {
    display: none;
  }
}

@use 'src/styles/legacy/variables' as *;

.item {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  border: 1px solid transparent;
  flex: 0 1 auto;
  height: 48px;
  margin: 0 0 2px 0;

  &:hover {
    cursor: pointer;
  }
}
.light {
  color: $ds-dark-2;
  background-color: $ds-light-9;
  border-color: $ds-light-9;
}
.dark {
  color: $ds-light-9;
  background-color: $ds-dark-2;
  border-color: $ds-dark-2;
}
.active {
  color: $ds-light-9;
  background-color: $ds-primary-default;
  border-color: $ds-primary-default;
}
.inactive {
  &:hover {
    border-color: $ds-dark-4;
  }
}
.icon {
  display: flex;
  justify-content: center;
  align-self: center;
  margin: 4px 8px;
}

@use 'src/styles/variables' as *;

@keyframes spin-around {
  0% {
    transform: translate(0px) rotate(120deg) translate(-28px);
  }
  100% {
    transform: translate(0px) rotate(480deg) translate(-28px);
  }
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .loadingLogo {
    position: relative;
    border-radius: 50%;
    background-color: $shadeWhite;
    width: 6px;
    height: 6px;
    transform: translate(18px, -20px);

    &.loading {
      animation: spin-around 1.6s ease infinite;
    }
  }

  svg {
    position: absolute;
  }
}

.label {
  text-align: center;
}

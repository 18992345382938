@use 'src/styles/legacy/variables' as *;

.inputNumber {
  display: flex;
  justify-content: space-between;

  .minMax {
    color: $color-black-text;
    font-size: 0.9rem;
    white-space: nowrap;
  }
  .inputField {
    margin: 0 1rem;
    flex: 1;
    text-align: center;
    max-width: 25%;
  }
}

@import '../../../../styles/variables/z-index';
@import '../../../../styles/variables/media-queries';

.container {
  margin: 12px;
  @media #{$small-screen} {
    margin: 5px;
  }
}
.bottomActions {
  display: flex;
  justify-content: center;
  margin: 2rem 1rem;
}
.nextButton {
  margin: 0 auto;
}
.scrollTopButton {
  z-index: $z-index-fixed-above;
}

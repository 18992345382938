// ! DEPRECATED ! Utiliser les couleurs présentes dans ./_colors_v3.scss

/**
 * Project theme
 *
 * Always use colors as variables
 */

// project colors
$main: #f1f1f1; //bg color
$text: #979797; //grey
$color1: #017fb1; //blue
$color2: #01729f; //dark blue
$color3: #00abbe; //blue green
$color4: #4fa600; //green validation
$color5: #00abbd;
$color6: #db90ae;
$color7: #539ccb;
$color8: #de4437;
$color9: #393d44;
$color10: #fda829;

$twitter: #6cdfea;
$facebook: #3b5998;
$instagram: #e4405f;

// shades of grey
$grey1: #d8d8d8; //button grey
$grey2: #dddddd; //light
$grey3: #333333; //dark grey
$grey4: #a9a9a9; //select + input
$grey5: #fbfcfc; //very light grey
$grey6: #d3d4e1; //very light grey
$grey7: #999aae; //medium grey
$grey8: #7d838d;
$grey9: #5d6471;
$grey10: #efebe8;
$grey11: #d4d5dd;
$grey12: #c8ccd6;

$disabled: #eeeff2;

$color-white: #ffffff;
$color-white-shadow: #fafbfd;
$color-grey: #a0a3b8;
$color-black: #555878;
$color-light-blue: #71b8fe;
$color-blue: #4a90e2;
$color-blue2: #5264ae;
$color-blue3: #638ec2;
$color-blue-darkened: #273142;
$color-blue-darkened2: #283142;
$color-green: #26bbb8;
$color-green-transparent: rgba(38, 187, 184, 0.25);
$color-red: #f54747;

// button
$color-blue-disabled: #adcbee;
$color-dark-blue-disabled: #b1b5c3;
$color-text-disabled: #f5f7f9;

$color-black-text: rgba(85, 88, 120, 0.4);
$color-blue-text: #4490e5;

$color-blue-shadow: rgba(74, 144, 226, 0.9);
$color-black-border: rgba(85, 88, 120, 0.25);
$color-grey-border: #dfe3e9;
$color-grey-thin-border: rgba(222, 226, 229, 0.2);
$color-white-border: rgba(255, 255, 255, 0.4);
$color-editor-border: rgba(211, 212, 225, 0.1);
// INPUT COMPONENT

$base-color-dark-theme: rgba(
  $color: $color-black,
  $alpha: 0.25,
);
$base-color-dark-theme2: rgba(
  $color: $color-black,
  $alpha: 0.4,
);
$text-color-dark-theme: $color-black;
$base-color-light-theme: rgba(
  $color: $color-white,
  $alpha: 0.25,
);
$base-color-light-theme2: rgba(
  $color: $color-white,
  $alpha: 0.4,
);
$text-color-light-theme: $color-white;

@use 'src/styles/legacy/variables' as *;

$active: $color-brand-primary;
$active-hover: $ds-primary-dark;
$inactive: $ds-light-5;
$inactive-hover: $ds-primary-dark;

.container {
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}

.label {
  padding-left: 12px;
  white-space: nowrap;
  &.small {
    padding-left: 6px;
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  background-color: $active;

  &.hovered {
    background-color: $active-hover;
  }

  &.small {
    height: 24px;
    width: 24px;
  }

  &.medium {
    height: 32px;
    width: 32px;
  }
}

.inactive {
  background-color: transparent;
  border: solid 1px $inactive;

  &.hovered {
    border: none;
    background-color: $inactive-hover;
  }
}

@use 'src/styles/variables' as *;

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 1rem 0;

  .title {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .description {
    font-weight: 500;
    margin-top: 0.75rem;
    color: $shade4;
  }
}

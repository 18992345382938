@use 'src/styles/variables' as *;

.contacts {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.contact {
  width: 100;
  @media #{$not-mobile} {
    width: 50%;
    padding: 0 12px;
  }
}

.accordionTitle {
  @include typo-base();
  font-weight: 600;
  font-size: toRem(18px);
  white-space: nowrap;
  @media #{$medium-screen} {
    @media #{$not-mobile} {
      font-size: 1.2vw;
    }
  }
  margin-right: toRem(10px);
  @media #{$medium-screen} {
    margin-right: toRem(5px);
  }
}

.accordionSubtitle {
  color: $shade4;
  font-weight: 500;
}

@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.layoutHelper {
  width: 100%;
  margin: 0 auto;
}
.message {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: $ds-dark-4;
}
.dropZone {
  position: fixed !important;
  z-index: $z-index-max;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@use 'src/styles/legacy/variables' as *;
@use 'src/styles/variables' as *;

.banner {
  display: flex;
  align-items: center;
  color: $color-content-light-1;
  min-height: 2.5rem;
  position: relative;
  z-index: $z-index-max;
  padding: 0 1rem;

  &.error {
    background-color: $color-error;
  }
  &.warning {
    background-color: $color-warning;
  }
}
.button {
  display: inline-flex;
  margin-left: auto;
}

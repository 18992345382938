@use 'src/styles/variables' as *;

.form {
  width: 48vw;
}

.fileContainer {
  @include fw-elevation-light-top-4();
  max-height: 70vh;
  overflow-y: auto;
  border-radius: 12px;
  background-color: transparent;

  &.focused {
    border-left: 4px solid $primaryDefault;
  }
}

@use 'src/styles/variables' as *;

.container {
  overflow-y: scroll;
  padding-bottom: 1rem;
  width: 46.25rem;
  background-color: $shade7;
}

.containerHtml {
  margin-left: 3rem;
  margin-right: 3rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  color: $shade3;
}
.scrollTopButton {
  z-index: $z-index-fixed-above;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.validationButton {
  padding: 0.5rem;
  display: flex;
  justify-content: flex-end;
}

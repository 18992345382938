@import '../../styles/variables/media-queries';

.container {
  max-width: 600px;
  @media #{$small-screen} {
    width: 100vw;
    max-width: 100vw;
  }
}
.mainContent {
  margin: 0.25rem 0;
}
.titleContainer {
  margin-bottom: 1rem;
  max-width: 600px;
  @media #{$small-screen} {
    margin-bottom: 0.5rem;
  }
}
.bottomActions {
  margin-top: 1rem;
  @media #{$small-screen} {
    margin-bottom: 0.5rem;
  }
}

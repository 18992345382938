@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

$column-width: 320px;

.form {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.fields {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
  padding: 1rem;
}
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: $column-width;
  width: 100%;
  padding: 0 0.5rem;
  margin: 10px 0;
}

.field {
  margin-right: toRem(18px);
}
.fieldTitle {
  flex-grow: 1;
  @media #{$not-mobile} {
    width: 7.5%;
  }
}
.fieldFamilyName {
  flex-grow: 2;
}
.fieldFirstName {
  flex-grow: 2;
}
.fieldProfession {
  flex-grow: 1;
}
.fieldIdentifier {
  flex-grow: 1;
}
.organization {
  width: 100%;
  border: 1px solid $color-content-light-3;
  border-radius: 8px;
  padding: 0.25rem;
  margin-block-start: 0.25rem;
  margin-inline-end: 0.25rem;
  @media #{$not-mobile} {
    padding: 0.5rem;
    width: calc(50% - 0.5rem);
    margin-block-start: 0.5rem;
    margin-inline-end: 0.5rem;
  }
}
.organizations {
  color: $color-content-light-2;
}

.inputSpacing {
  padding-top: 0.5rem;
}

@use './colors_v3' as *;
@use './sizes' as *;
@use '../legacy/variables' as *;

$font-weight-normal: 500;
$font-weight-bold: 600;

@function validateFontColor($font-color) {
  @if ($font-color != $ds-dark-2) and
    ($font-color != $ds-dark-4) and
    ($font-color != $ds-light-6) and
    ($font-color != $ds-white) and
    ($font-color != $ds-primary-default) and
    ($font-color != $ds-warning) and
    ($font-color != $ds-secondary-default) and
    ($font-color != $ds-grey-blue) and
    ($font-color != $ds-error)
  {
    @error "Invalid color #{$font-color}.";
  }
  @return $font-color;
}

@function validateFontWeight($font-weight) {
  @if ($font-weight != $font-weight-normal) and ($font-weight != $font-weight-bold) {
    @error "Invalid font-weight #{$font-weight}.";
  }
  @return $font-weight + 100;
}

@mixin heading-common($font-size, $line-height, $font-color, $font-weight) {
  font-family: Montserrat;
  letter-spacing: normal;
  font-stretch: normal;
  font-style: normal;
  font-weight: validateFontWeight($font-weight);
  color: validateFontColor($font-color);
  font-size: toRem($font-size);
  line-height: $line-height;
  margin: 0;

  // outline: 1px dashed rgb(255, 0, 0);
  // box-shadow: 0 0 0 1px rgb(0, 255, 42);
}

@mixin heading1($font-weight, $font-color) {
  @include heading-common(36px, 1.33, $font-color, $font-weight);
}

@mixin heading2($font-weight, $font-color) {
  @include heading-common(28px, 1.21, $font-color, $font-weight);
}

@mixin heading3($font-weight, $font-color) {
  @include heading-common(21px, 1.33, $font-color, $font-weight);
}

@mixin heading4($font-weight, $font-color) {
  @include heading-common(16px, 1.75, $font-color, $font-weight);
  letter-spacing: 1px;
  text-transform: uppercase;
}

@mixin heading5($font-weight, $font-color) {
  @include heading-common(18px, 1.44, $font-color, $font-weight);
}

@mixin heading6($font-weight, $font-color) {
  @include heading-common(16px, 1.5, $font-color, $font-weight);
}

@mixin paragraph($font-weight, $font-color) {
  @include heading-common(16px, 1.5, $font-color, $font-weight);
}

@mixin footnote($font-weight, $font-color) {
  @include heading-common(12px, 1.33, $font-color, $font-weight);
}

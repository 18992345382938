@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.container {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;

  .box {
    width: 88px;
    height: 72px;
    border-radius: 4px;
    border: solid 1px $ds-light-6;
    display: flex;
    flex-flow: column;
    will-change: border-color;
    transition: border-color 0.5s ease;
    cursor: pointer;
  }
  .label {
    font-size: 12px;
    line-height: 1.33;
    text-align: center;
    display: block;
    color: $ds-grey-blue;
    margin-top: 16px;
    transition: color 0.5s ease;
  }
  &:hover {
    .box {
      border-color: $ds-primary-default;
    }
    .label {
      color: $ds-dark-1;
    }
  }
}
.row {
  display: flex;
  flex: 1;
  margin: 2px 8px;
  align-items: center;
  justify-content: flex-start;
}
.input {
  width: 16px;
  height: 16px;
  border: solid 1px $ds-light-6;
}
.radio {
  border-radius: 8px;
}
.checkbox {
  border-radius: 4px;
}
.text {
  width: 48px;
  height: 8px;
  border-radius: 2px;
  background-color: $ds-light-6;
  margin: auto;
  &.short {
    margin: auto 0;
    width: toRem(24px);
  }
  &.long1 {
    margin: auto 0;
    width: toRem(64px);
  }
  &.long2 {
    margin: auto 0;
    width: toRem(72px);
  }
  &.long3 {
    margin: auto 0;
    width: toRem(48px);
  }
}
.slider {
  position: relative;
  width: 100%;

  &::after,
  &::before {
    content: '';
    display: block;
  }
  &::before {
    width: 72px;
    height: 8px;
    border-radius: 4px;
    background-color: $ds-primary-default;
    background: linear-gradient(90deg, $ds-primary-default 50%, $ds-light-6 50%);
    position: absolute;
    top: calc(50% - 4px);
  }
  &::after {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    background-color: $ds-white;
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% - 12px);
  }
}
.active {
  .input {
    background-color: $ds-primary-default;
    border: none;
    display: flex;
    &::before {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      background-color: $ds-white;
      margin: auto;
    }
  }
  .checkbox {
    &::before {
      border-radius: 1px;
    }
  }
  .radio {
    &::before {
      border-radius: 8px;
    }
  }
  .text {
    background-color: $ds-dark-3;
  }
}

@use 'src/styles/legacy/variables' as *;

.valid {
  border-color: $color-success;
}

.invalid {
  border-color: $color-error;
}

.errorLabel {
  margin-top: 5px;
  margin-left: 5px;
  color: $color-error;
  font-size: 12px;
}

@use 'src/styles/legacy/variables' as *;

.actionsContainer {
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
}

.body {
  background-color: $twGray50;
  min-width: 600px;
  padding: 1.5rem;
  width: 100%;

  .fieldsContainer {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
  }
}

@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.header {
  grid-area: header;
  padding: 15px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  font-size: toRem(16px);
  font-weight: 600;
  color: $color-background-dark-1;
  background-color: var(--fw-color-shades-white);
}

.headerLeft {
  flex-shrink: 0;
}

.headerRight {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}

.selectionLabel {
  display: flex;
  align-items: center;
}

.selectAllCheckbox {
  margin-right: 8px;
}

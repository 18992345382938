@import '../../../styles/variables/sizes';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: $viewport-height-without-navbar;
  margin-top: $navbar-height;

  .content {
    margin-left: 40px;
    .headings {
      max-width: 530px;
      > * {
        &:first-child {
          margin-bottom: 24px;
        }
      }
    }
    .buttonContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 280px;
      margin-top: 32px;
      gap: 24px 0;
    }
  }
}

@use 'src/styles/variables' as *;

.quoteLineFormContainer {
  display: flex;
  flex-direction: column;
  height: 100%;

  .header {
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    align-items: center;
    margin-bottom: 1rem;

    .summary {
      display: flex;
      flex-direction: column;

      .title {
        font-weight: 500;
        color: $shade4;
        text-transform: uppercase;
      }

      .preview {
        color: $shade2;
        font-size: 0.875rem;
        line-height: 1.25;
      }
    }
  }

  .quoteLineFormContent {
    display: flex;
    justify-content: space-between;

    > div {
      width: 100%;
      margin: 0.25rem;
    }
  }
}

@use 'src/styles/variables' as *;

.container {
  background-color: $shadeWhite;
  width: 100vw;
  height: 100vh;

  .backWrapper {
    display: flex;
    padding: 1em 2em;
    align-items: center;
    height: 72px;

    .backReturnText {
      font-weight: 600;
      color: $shade3;
      font-size: 1.2em;
      margin-left: 1em;
    }
  }

  .iframe {
    width: 100%;
    height: calc(100% - 72px);
  }
}

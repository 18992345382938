@use 'src/styles/legacy/variables' as *;

.table {
  border-collapse: separate;
  border-spacing: 0 1rem;
  width: 100%;

  .colCheckbox,
  .colCdaContext,
  .colActions {
    width: 0;
  }

  .colTitle {
    min-width: 14rem;
  }

  .colCategory,
  .colDocument,
  .colDocumentStep,
  .colAssignedTo,
  .colPatient,
  .colCreatedAt {
    width: 14rem;
  }

  thead {
    color: $ds-primary-darker;
    fill: $ds-primary-darker;
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1rem;

    th {
      padding-left: 1rem;
      padding-right: 1rem;

      .headerWrapper {
        align-items: center;
        display: flex;
        flex-direction: row;

        & > :not([hidden]) ~ :not([hidden]) {
          margin-left: 0.5rem;
          margin-right: 0;
        }

        &.clickable {
          cursor: pointer;
        }
      }
    }
  }

  /** Size **/
  &.small {
    border-spacing: 0 0.5rem;
  }
}

@use 'src/styles/variables' as *;

.birthPlaceCodeHelpContainer {
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 8px 0 4px 0;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 500;

  .birthPlaceCodeTextContainer {
    display: flex;
    align-items: center;
    gap: 4px;

    .addbirthPlaceCode {
      color: $shadeWhite;
      background-color: $primaryLight;
      font-size: 0.8rem;
      border-radius: 20px;
      padding: 2px 6px;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
}

.birthPlaceCodeSearchButton {
  font-size: 0.75rem;
  line-height: 1rem;
  color: $statusNormal;

  &:hover {
    color: $primaryDark;
  }
}

@use 'src/styles/variables' as *;

.row {
  background-color: $shadeWhite;
  border-radius: 0.5rem;
  color: $shade2;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  transition-duration: 150ms;
  transition-property: box-shadow;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  .unreadChip {
    background-color: $statusNormal;
    border-radius: 9999px;
    display: inline-block;
    height: 0.75rem;
    margin-right: 0.5rem;
    width: 0.75rem;
  }

  .clickable {
    cursor: pointer;
  }

  .nameInput {
    background-color: transparent;
    caret-color: $statusNormal;
    outline: 2px solid transparent;
    outline-offset: 2px;
    resize: none;
    width: 100%;

    &::placeholder {
      color: $shade6;
    }
  }

  td {
    border-bottom-width: 1px;
    border-color: $shade6;
    border-top-width: 1px;
    min-height: 4rem;

    &.dataCellTitle {
      align-items: center;
      display: flex;
      padding: 1rem;
    }

    &.dataCellCheckbox,
    &.dataCellCategory,
    &.dataCellDocument,
    &.dataCellDocumentStep,
    &.dataCellPatient,
    &.dataCellCreatedAt {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    &.dataCellAssignedTo {
      padding: 1rem;
    }

    &.dataCellCdaContext {
      .dataCellCdaContextWrapper {
        display: flex;
        gap: 0.5rem;
      }
    }

    &.dataCellActions {
      .dataCellActionsWrapper {
        display: flex;
        column-gap: 1rem;
        flex-direction: row;
        justify-content: center;
        opacity: 0;
        transition-duration: 500ms;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

        .validateBtn {
          &:hover {
            fill: $statusValid;
          }
        }
      }
    }

    &:first-child {
      border-bottom-left-radius: 0.5rem;
      border-top-left-radius: 0.5rem;
      border-left-width: 1px;
    }

    &:last-child {
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      border-right-width: 1px;
    }
  }

  &.statusDone {
    background-color: $shade7;
  }

  &.read {
    .nameInput {
      font-weight: 300;
    }
  }

  &:hover {
    td {
      &.dataCellActions {
        .dataCellActionsWrapper {
          opacity: 100;
        }
      }
    }
  }

  &.isInvalid {
    td {
      &.dataCellActions {
        .dataCellActionsWrapper {
          .validateBtn {
            opacity: 0.25;
            pointer-events: none;
          }
        }
      }
    }
  }

  /** Edit mode **/
  &:hover,
  &.editMode {
    box-shadow:
      0 0.625rem 0.9375rem -0.1875rem rgba(0, 0, 0, 0.1),
      0 0.25rem 0.375rem -0.25rem rgba(0, 0, 0, 0.1);
  }

  &.editMode {
    td {
      border-bottom-width: 2px;
      border-color: $statusNormal;
      border-top-width: 2px;

      &.dataCellActions {
        .dataCellActionsWrapper {
          opacity: 100;
        }
      }

      &:first-child {
        border-left-width: 2px;
      }

      &:last-child {
        border-right-width: 2px;
      }
    }
  }

  /** Size **/
  &.small {
    &:hover,
    &.editMode {
      box-shadow:
        0 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1),
        0 0.125rem 0.25rem -0.125rem rgba(0, 0, 0, 0.1);
    }

    td {
      min-height: 2rem;
    }
  }
}

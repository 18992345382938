@use 'src/styles/legacy/variables' as *;

.container {
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto;
  grid-auto-flow: dense;
  justify-items: center;
  column-gap: 16px;

  :global {
    .rc-slider {
      height: 4px;
      padding: 0;

      &-track {
        background-color: $ds-primary-default;
      }
      &-handle {
        border-radius: 4px;
        background-color: $ds-dark-1;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
        border: 0;
      }
      &-disabled {
        .rc-slider-handle {
          background-color: $ds-grey-blue;
        }
      }
    }

    .noData {
      .rc-slider {
        &-track {
          background-color: $ds-grey-blue;
        }
      }
    }
  }
  .empty {
    :global {
      .rc-slider-track {
        background-color: $ds-grey-blue;
      }
    }
  }
}
.sliderValues {
  color: $ds-grey-blue;
  font-size: 1rem;
  font-weight: normal;
  line-height: 2rem;
  align-self: end;

  &.min {
    grid-column: 1;
  }
  &.max {
    grid-column: 3;
  }
}

.slider {
  width: 100%;
  align-self: center;
  margin-top: 12px;

  &.complete {
    grid-column: span 3;
  }
  &.compact {
    grid-column: span 1;
  }
}

.inputField {
  width: 100px;

  input {
    font-size: 0.9rem;
    font-weight: bold;
  }
}

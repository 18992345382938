.actions {
  display: flex;
  flex-direction: row;

  .button {
    margin: 0 10px;
  }
}

.container {
  height: 100%;
  overflow-y: auto;
}

@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.container {
  display: flex;
  flex-direction: row;
  background-color: white;
  align-items: stretch;
  justify-content: space-between;
  min-height: 50px;
  border-top: 1px solid var(--fw-color-shades-shade7);
  border-bottom: 1px solid var(--fw-color-shades-shade7);
  border-right: 5px solid transparent;
  transition: background-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  box-sizing: border-box;

  &.focused {
    background-color: var(--fw-color-shades-shade8);
    border-right-color: var(--fw-color-primary-default);
  }
}

.primaryAction {
  cursor: pointer;

  &:hover {
    background-color: var(--fw-color-shades-shade8);
  }
}

.itemCheck {
  margin: 0px 16px;
  display: flex;
  align-items: center;
  @media #{$small-screen} {
    margin: 0px 5px;
  }
}

.itemContent {
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 15px 0px;
  min-width: 0;
  @media #{$small-screen} {
    font-size: toRem(13px);
  }
}

.itemActions {
  margin: 0px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-basis: 5%;
  @media #{$small-screen} {
    margin: 0px 5px;
  }

  > *:not(:first-child) {
    margin-left: 0.1rem;

    @media #{$not-mobile} {
      margin-left: 0.5rem;
    }
  }
}

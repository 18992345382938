@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.container {
  background-color: var(--fw-color-shades-shade7);
  padding: 1rem 1rem 0.5rem 1rem;

  max-height: 500px;
  transition: max-height 0.15s ease-out;

  @media #{$small-screen} {
    padding: 0.5rem;
  }
}

.collapsed {
  max-height: 0;
  padding: 0;
  overflow: hidden;
}

.content {
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: toRem(16px);
  }

  @media #{$small-screen} {
    flex-direction: row-reverse;
  }
}
.separator {
  height: 40px;
  width: 2px;
  border-radius: 1px;
  background-color: var(--fw-color-shades-shade5);
}

.ownerCreatorName {
  color: var(--fw-color-shades-shade4);
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-style: italic;
}

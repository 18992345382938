@use 'src/styles/variables' as *;

.itemWrapper {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;

  .title {
    align-items: center;
    display: flex;
    flex-direction: row;
    column-gap: 0.3rem;
  }

  .description {
    align-items: center;
    display: flex;
    flex-direction: row;
    column-gap: 0.3rem;
    color: $shade4;
    font-weight: 500;
    font-size: 0.9rem;
  }
}

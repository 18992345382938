@use 'src/styles/variables' as *;

.form {
  display: flex;
  align-items: center;
  label {
    margin-right: 5px;
  }
}

.pendingValueLabel {
  display: grid;
  color: $labelOrange;
  margin-bottom: 0.5rem;

  .pendingValue {
    max-width: 100%;
    padding-left: 0.25rem;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
    justify-self: start;
  }
}

@import '../../../../styles/variables/sizes';

.container {
  display: flex;
  align-items: center;
  width: fit-content;
  min-height: toRem(24px);
  color: white;
  border-radius: 4px;
  padding: 0 8px;
  font-size: toRem(16px);
  font-weight: 500;
  white-space: nowrap;

  &.multiline {
    white-space: normal;
    max-width: none;
  }

  .ellipsis {
    max-width: toRem(200px);
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .cross {
    padding-left: 2px;
    margin-bottom: -1px;
    cursor: pointer;
  }
}

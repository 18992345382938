@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.container {
  width: 100%;
  display: flex;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  @media #{$small-screen} {
    flex-direction: column;
  }
  .filters {
    flex: 2;
    background-color: var(--fw-color-shades-shade2);
    overflow-y: scroll;
  }
  .list {
    overflow-y: auto;
    position: relative;
    flex: 5;
  }
}

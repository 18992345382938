@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.icon {
  display: none;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 44px;
  border-radius: 50%;
  background-color: var(--fw-color-shades-shade3);

  &.consultation_note {
    background-color: var(--fw-color-primary-dark);
  }

  &.discharge_summary_note,
  &.surgical_operation_note {
    background-color: var(--fw-color-secondary-dark);
  }

  &.other {
    background-color: var(--fw-color-shades-shade2);
  }

  &.AddMedicalEventAction {
    background-color: $ds-primary-default;
    cursor: pointer;

    &:hover {
      background-color: $primaryDark;
    }
  }

  @media #{$not-mobile} {
    display: flex;
  }
}

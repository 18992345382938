@use 'src/styles/variables' as *;

.overlay {
  @include fw-elevation-light-top-4();
  display: grid;
  grid-auto-flow: row;
  position: absolute;
  left: 0;
  width: 100%;
  z-index: $z-index-10;
  background-color: var(--fw-color-shades-white);
  border-radius: 4px;
  gap: 4px;
  padding: 4px;
  max-height: 264px;
  overflow-y: auto;
  overscroll-behavior-y: contain;

  &.bottom {
    top: calc(100% + 2px);
  }

  &.top {
    bottom: 100%;
  }
}

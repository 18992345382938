@use 'src/styles/legacy/variables' as *;

.actions {
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  width: 100%;
  justify-content: end;
}

.content {
  display: flex;
  max-height: 100%;
  overflow: hidden;
}

.column {
  overflow: hidden scroll;
  width: 30rem;
  padding: 1rem;
  position: relative;

  &.left {
    background-color: $twGray50;
  }

  &.right {
    background-color: $twGray100;
  }
}

@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.floatingTitle {
  position: absolute;
  top: 0;
  left: 0;
  padding: 1rem;
  width: 100%;
  z-index: $z-index-10;
}

.unsuportedFile {
  display: flex;
  flex-direction: row;
  .icon {
    display: flex;
    align-items: center;
    margin-right: 20px;
    svg {
      height: 128px;
      & > path {
        fill: $ds-primary-light;
      }
    }
  }
  .labels {
    display: flex;
    align-items: center;
    .title {
      font-size: 24px;
      margin-bottom: 10px;
    }
  }
}

.visibleNameContainer {
  display: flex;
}

.floating {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  bottom: 30px;
  right: 30px;
  > :first-child {
    margin-right: 30px;
  }
}

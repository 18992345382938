@import '../../../../styles/variables/media-queries';
@import '../../../../styles/variables/z-index';

.mobileAddMedicalEvent {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: $z-index-fixed-above;
  @media #{$not-mobile} {
    display: none;
  }
}

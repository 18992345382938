.colors {
  display: flex;
  flex-direction: row;

  > :first-child {
    margin-left: 0;
  }
  > :last-child {
    margin-right: 0;
  }
}

@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;
@use '../BottomPanel.common.scss' as *;

.topBar {
  background-color: var(--fw-color-shades-shade1);
  @include heading6($font-weight-bold, $ds-white);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media #{$not-mobile} {
  .topBar {
    top: 28%;
    height: $bottomPanelHeaderHeight;
    padding: 0px 15px;
    border-radius: 8px 8px 0px 0px;
    &.maximized {
      border-radius: 0px;
    }
  }
  .defaultActionsDesktop {
    display: flex;
    flex-direction: row;
    z-index: $z-index-base;
    > * {
      margin-right: 10px;
    }
  }
  .defaultActionsMobile {
    z-index: $z-index-base;
    display: none;
    flex-direction: row;
  }
  .title {
    @include typo-input-size-big();
  }
  .actions {
    display: flex;
    z-index: $z-index-base;
    flex-grow: 0;
    & > *:not(:last-child) {
      margin-right: 8px;
    }
  }
}

@media #{$small-screen} {
  .topBar {
    height: 60px;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    justify-content: center;
    &.hidden {
      display: none;
    }
  }
  .defaultActionsDesktop {
    display: none;
  }
  .defaultActionsMobile {
    position: fixed;
    left: 10px;
    bottom: 8px;
    z-index: $z-index-base;
  }

  .actions {
    position: fixed;
    right: 10px;
    bottom: 8px;
    z-index: $z-index-base;
  }
}

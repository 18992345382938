@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.fileDropZone {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  animation: fadeIn 0.3s ease-in-out forwards;
  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
  &.dark {
    background-color: var(--fw-color-dimmer-dark);
  }
  &.light {
    background-color: transparent;
  }

  .dropZone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 90%;
    height: 90%;
    margin: 20px 0;
    border-width: 3px;
    border-style: dashed;
    border-radius: 10px;
    flex-grow: 1;
    cursor: pointer;
    &:hover {
      background-color: $color-content-light-3;
    }
    & * {
      pointer-events: none;
    }
    &.fileHover {
      background-color: $color-content-light-3;
    }
    > * {
      margin: 10px 5px;
    }

    &.dark {
      border-color: white;
    }
    &.light {
      border-color: $dimmerDark;
    }
    &.error {
      background-color: $color-content-light-3;
    }
  }
  h1 {
    font-size: 1.5em;
    @media #{$not-mobile} {
      font-size: 1.5em;
    }
    &.dark {
      color: $color-white;
    }
    &.light {
      color: $dimmerDark;
    }
  }
  h2 {
    font-size: 0.8em;
    margin: 0.5rem;
    text-align: center;
    font-weight: 500;
    @media #{$not-mobile} {
      font-size: 1em;
    }
    &.dark {
      color: $color-white-shadow;
    }
    &.light {
      color: $dimmerDark;
    }
  }

  p.dark {
    color: $color-white;
  }
  p.light {
    color: $dimmerDark;
  }
  p.error {
    color: $statusInvalid;
  }

  input {
    display: none;
  }
  label {
    color: $main;
    margin: 0;
    padding: 10px;
    font-weight: normal;
    text-align: center;
    border: 1px white solid;
    border-radius: 50px;
    font-size: 0.8rem;
    &:hover {
      text-decoration: none;
    }
    cursor: pointer;
  }
  a {
    color: $main;
    margin-bottom: 10px;
    font-weight: normal;
    text-align: center;
    text-decoration: underline;
  }
  .iconClose {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .browse {
    display: none;
  }
}

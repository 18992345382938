@import '../../../styles/variables/mixins';

$light-background: var(--fw-color-shades-shade7);
$dark: var(--fw-color-shades-shade4);
$light: var(--fw-color-shades-white);
$primary-background: var(--fw-color-primary-dark);

.userAvatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
  margin: 5px 0;
  &.large {
    width: 48px;
    height: 48px;
  }
  &.selectable {
    &:hover {
      box-sizing: border-box;
      cursor: pointer;
    }
  }
  &.light {
    background: $light-background;
    &.selected {
      border: 2px solid $dark;
    }
    &.selectable {
      &:hover {
        border: 2px solid $dark;
      }
    }
    .initials {
      color: $dark;
    }
  }
  &.primary {
    background: $primary-background;
    &.selected {
      border: 2px solid $light;
    }
    &.selectable {
      &:hover {
        border: 2px solid $light;
      }
    }
    .initials {
      color: $light;
    }
  }
  &.small {
    width: 32px;
    height: 32px;
    .initials {
      @include typo-input-size-small();
    }
  }
  &.medium {
    width: 40px;
    height: 40px;
    .initials {
      @include typo-input-size-regular();
    }
  }
  .initials {
    @include typo-base();
    @include typo-input-value();
    @include typo-input-size-big();
  }
  .star {
    position: absolute;
    top: 0;
    right: 0;
  }
}

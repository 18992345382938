@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.autocompleteInput {
  position: relative;
}
.suggestions {
  background-color: $ds-light-8;
  box-shadow: 0 18px 30px rgba($ds-dark-4, 0.8);
  border: solid 1px $ds-light-8;
  border-radius: 4px;
  left: 0;
  min-width: 285px;
  opacity: 0;
  position: absolute;
  transition: opacity 0.3s ease-in-out;
  width: 100%;
  z-index: $z-index-max;
  overflow-y: auto;
  max-height: 400px;
  &.displayed {
    opacity: 1;
  }
}
.suggestionsContent {
  width: 100%;
  padding: 8px 0;
  max-height: 215px;
  overflow-y: auto;
}
.suggestionItem {
  text-align: left;
  color: $ds-dark-2;
  cursor: pointer;
  border-radius: 4px;

  @include paragraph($font-weight-normal, $ds-dark-2);

  &:hover,
  &.hover {
    color: $ds-light-9;
    background-color: $color-brand-primary;
    svg > g {
      fill: $ds-light-9;
    }
  }
  &:not(:last-child) {
    border-bottom: 1px $ds-light-7 solid;
  }
}

@use 'src/styles/variables' as *;

.loaderContainer {
  width: 100%;
  position: relative;
}

.noMailFallback {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  color: $shade2;
}

@use 'src/styles/legacy/variables' as *;

.radioGroup {
  font-weight: 500;
  color: var(--fw-color-shades-shade2);
}
.label {
  color: var(--fw-color-shades-shade4);
  font-size: 0.75rem;
}
.wrapper {
  display: flex;
  flex-wrap: wrap;

  & > *:not(:last-child) {
    margin-right: 24px;
  }
}

@import '../../../../../styles/variables/mixins';
@import '../../../../../styles/variables/sizes';

.accountItem {
  @include typo-base;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  max-height: 47px;
  font-size: toRem(16px);
  color: var(--fw-color-shades-shade2);
}

.fileHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  .fileNameWithTypeIcon {
    display: flex;
    align-items: center;
    gap: 12px;

    .fileIcon {
      min-width: 16px;
    }

    .fileName {
      font-size: 18px;
      line-height: 26px;
    }
  }

  .iconsContainer {
    display: flex;
    gap: 12px;
  }
}

@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

$transition-duration: 0.2s;

.container {
  position: relative;
  @include typo-base();
  width: 100%;

  .content {
    display: flex;
    align-items: center;

    &.smallHeight {
      height: 32px;
    }
    &.mediumHeight {
      min-height: 52px;
    }

    &.underline {
      border-bottom: 1px solid;
      transition: border $transition-duration ease-in-out;
      &.invalid {
        border-color: $color-error !important;
      }

      &.valid {
        border-color: $color-success !important;
      }
    }
  }

  .wrapper {
    padding: 0 8px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .error {
    margin-top: 5px;
    margin-left: 5px;
    color: $color-error;
    font-size: 12px;
  }

  .label {
    font-size: toRem(12px);
  }

  .iconWrapper {
    padding-left: 8px;
  }

  .input {
    @include typo-base();
    appearance: none;
    border: none;
    background: transparent;
    font-size: inherit;
    width: 100%;
    font-size: 16px;
    height: 24px;

    &:focus {
      outline: none;
    }

    &.centered {
      text-align: center;
    }

    // Icone de l'input calendrier
    &::-webkit-calendar-picker-indicator:hover {
      cursor: pointer;
    }
    &.light {
      &::-webkit-calendar-picker-indicator {
        filter: invert(1);
      }
    }
  }

  &.dark {
    .input {
      color: var(--fw-color-shades-white);

      &::placeholder {
        color: var(--fw-color-shades-shade5);
      }

      &:disabled {
        color: var(--fw-color-shades-shade5);
        cursor: not-allowed;
      }
    }

    .label {
      color: var(--fw-color-shades-shade5);
    }

    .content {
      border-color: var(--fw-color-shades-shade4);
    }
  }

  &.light {
    .input {
      color: var(--fw-color-shades-shade2);

      &::placeholder {
        color: var(--fw-color-shades-shade5);
      }

      &:disabled {
        color: var(--fw-color-shades-shade4);
        cursor: not-allowed;
      }
    }

    .label {
      color: var(--fw-color-shades-shade4);
    }

    .content {
      border-color: var(--fw-color-shades-shade5);
    }
  }
}

.input {
  &.readonly {
    cursor: not-allowed;
  }
}

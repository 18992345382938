@use './base' as *;
@use './variables' as *;

label {
  text-transform: initial;
}

.section-title {
  letter-spacing: 0px;
}

html {
  font-size: 16px;
  --navbar-height: 0px;
}

body {
  font-size: 0.9rem; // 14px
}

// Masquage du bouton ZenDesk en mode mobile
iframe#launcher {
  @media #{$small-screen} {
    display: none;
  }
}

// Affichage des variables code-barres dans l'éditeur https://follow.myjetbrains.com/youtrack/issue/MAIN-3151
.imageContainer {
  display: inline-grid;
  text-align: center;

  img {
    display: inline;
  }
}

// Dev tools React Query
.tsqd-open-btn-container {
  transform: translate(-20px, -20px);
  transition: transform 0.5s ease;

  &:hover {
    transform: none;
  }
}

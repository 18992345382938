.item {
  display: flex;
  flex: 1;
  height: 42px;
  justify-content: space-between;
  align-items: center;

  .name {
    display: flex;
    flex-basis: 50%;
    align-items: center;
  }
  .identifier {
    display: flex;
    flex-basis: 30%;
  }

  .type {
    display: flex;
    flex-basis: 15%;
  }
  .creationDate {
    display: flex;
    flex-basis: 10%;
  }
}

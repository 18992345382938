.wrapper {
  background-color: white;
  width: 100%;
  font-weight: 500;
  color: var(--fw-color-shades-shade2);

  &.disabled {
    background-color: var(--fw-color-shades-shade8);
  }
}

.history_container {
  display: flex;
  margin-top: 0.5rem;
  height: 1rem;
  font-size: 0.75rem;
  line-height: 1rem;
}

.history_button_container {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 0.5rem;
}

.update_label {
  color: var(--fw-color-shades-shade4);
  margin-left: 0.5rem;
}

.history_label {
  color: var(--fw-color-primary-default);
  margin-left: 0.5rem;
}

.cardContent {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 30px;

  .selectContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.itemSelect {
  display: flex;
  flex-direction: column;
  min-width: 0;
  label {
    color: $ds-light-7;
    font-weight: 500;
    margin-bottom: 15px;
  }
  .itemsContainer {
    display: flex;
    flex-wrap: wrap;
    .item {
      cursor: pointer;
      margin: 10px 5px;
      display: flex;
      max-width: 100%;
    }
  }
  .showMore {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 10px;
    margin-right: auto;
    .label {
      font-size: toRem(12px);
      margin-right: 10px;
      color: $color-brand-primary;
      font-weight: 600;
    }
  }
}

$shadowColor: rgba(34, 34, 34, 0.5);
$shadowSecondaryColor: rgba(253, 140, 233, 0);

.links {
  &:not(.usurpation) {
    --fw-desktop-navigation-background: var(--fw-color-primary-default);
  }

  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100%;

  background:
    linear-gradient(var(--fw-desktop-navigation-background) 33%, $shadowSecondaryColor),
    linear-gradient($shadowSecondaryColor, var(--fw-desktop-navigation-background) 66%) 0 100%,
    radial-gradient(farthest-side at 50% 0, $shadowColor, rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, $shadowColor, rgba(0, 0, 0, 0)) 0 100%;
  background-color: var(--fw-desktop-navigation-background);
  background-repeat: no-repeat;
  background-attachment: local, local, scroll, scroll;
  background-size:
    100% 45px,
    100% 45px,
    100% 15px,
    100% 15px;
}

@import '../../../../styles/variables/mixins';
@import '../../../../styles/variables/sizes';

.features {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-block-start: 0.5rem;
  margin-block-end: 0.5rem;

  & > *:not(:last-child) {
    border-bottom: var(--fw-color-shades-shade7) solid 1px;
  }

  .featuresItem {
    @include typo-base;
    padding: 12px 0;
    font-size: toRem(16px);
    color: var(--fw-color-shades-shade2);
  }

  .dropdownItem {
    margin: 0.5rem 0;
  }
}

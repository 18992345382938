@use 'src/styles/variables' as *;

.container {
  width: 15rem;
}

.displayValueWrapper {
  display: flex;
}

.assignedValue {
  display: inline;
}

.iconWrapper {
  margin-top: 0.5rem;
  margin-left: 0.75rem;
}

$snackbar-shadow: 4px 5px 15px 2px rgba(0, 0, 0, 0.2);
$snackbar-text-color: #fff;
$snackbar-background-default-color: #273142;

$snackbar-background-error-color: #ed1c24;
$snackbar-background-warn-color: #f76b1c;
$snackbar-background-info-color: rgb(58, 109, 250);
$snackbar-background-valid-color: #26bbb8;

.snackbar {
  display: flex;
  background-color: $snackbar-background-default-color;
  color: $snackbar-text-color;
  border-radius: 4px;
  font-family: 'Montserrat', sans-serif;
  padding: 10px 15px;
  max-width: 800px;
  margin-top: 10px;
  font-weight: 600;
  min-height: 40px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: $snackbar-shadow;
  pointer-events: auto;
}

.icon {
  background-color: #ccc;
  border-radius: 50%;
  height: 28px;
  width: 28px;
  margin: 0px 15px 0px 5px;
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  align-items: center;
  justify-content: center;

  &.error {
    background-color: $snackbar-background-error-color;
  }

  &.warn {
    background-color: $snackbar-background-warn-color;
  }

  &.info {
    background-color: $snackbar-background-info-color;
  }

  &.valid {
    background-color: $snackbar-background-valid-color;
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 25px;
}

.message {
  font-size: 11pt;
  font-weight: normal;
  margin-top: 3px;
}

@use 'src/styles/variables' as *;

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0;
  font-size: toRem(13px);
  .value {
    text-align: right;
    font-weight: 600;
  }
}

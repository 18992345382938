@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.container {
  display: flex;
}

.label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-grow: 1;
}

.actions {
  display: flex;
  > *:not(:last-of-type) {
    margin-right: 8px;
  }
}

@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

$dark: var(--fw-color-shades-shade2);
$light: var(--fw-color-shades-white);
$primary: var(--fw-color-primary-default);

.item {
  display: flex;
  flex: 1;
  align-items: center;
  height: max-content;
  text-align: left;
  white-space: nowrap;
  background-color: transparent;
  fill: $dark;
  border-radius: 4px;
  font-size: toRem(16px);
  font-weight: 500;
  cursor: pointer;

  &.normal {
    min-height: 48px;
    padding: 1rem 0.75rem;
  }

  &.thin {
    min-height: 36px;
    padding: 0.25rem 0.5rem;
  }

  .icon {
    display: flex;
    fill: $dark;
    margin-right: 16px;
  }

  &.right {
    justify-content: space-between;
    > .icon {
      margin-right: 0px;
    }
  }
  &.dark {
    color: $dark;
  }
  &.light {
    color: $dark;
  }
  &:hover {
    background-color: var(--fw-color-primary-washed);
  }
  &.selected {
    color: $light;
    background-color: $primary;
    fill: $light;
    .icon {
      fill: $light;
    }
  }
}

div::-webkit-scrollbar {
  width: 8px;
}

div::-webkit-scrollbar-track {
  background: transparent;
}

div::-webkit-scrollbar-thumb {
  margin: 10px;
  width: 4px;
  border-radius: 100px;
  background-color: #bfbfc3;
  opacity: 0.4;
}

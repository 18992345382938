@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.answer {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  .labelWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: 50%;
    .label {
      flex: 1;
    }
    .points {
      width: 20%;
      margin-left: 12px;
    }
  }
  .buttonsWrapper {
    margin-left: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }
  .actionsWrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-start;
    margin-left: 12px;
    max-width: 20rem;

    > *:not(:last-child) {
      margin-right: 1rem;
    }
  }
}
.textOutputEditor {
  margin: 1rem 0;
}
.dropZone {
  position: fixed !important;
  z-index: $z-index-max;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.timelineWrapper {
  @media #{$not-mobile} {
    padding: 0px 50px 50px;
  }
}

.line {
  background-color: $color-content-dark-3;
  width: 100%;
  height: 100%;
}

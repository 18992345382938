@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.grid {
  display: grid;
  grid-template-columns: 4.5rem 1fr;
  align-items: end;
  width: 100%;

  .label {
    @include typo-base();
    color: var(--fw-color-shades-shade4);
    grid-column: span 2;
    font-size: toRem(12px);
    padding: 0 8px;
  }

  .error {
    grid-column: span 2;
    margin-top: 5px;
    margin-left: 5px;
    color: $color-error;
    font-size: 12px;
    font-weight: 500;
  }

  &.focusCodeInput {
    grid-template-columns: 1fr 8rem;
  }
}

@use 'src/styles/variables/index' as *;

.mailListContainer {
  display: flex;
  flex-direction: column;
  width: 20vw;
  height: inherit;
  background-color: $shadeWhite;

  .mailActionsWithSearch {
    padding: 1rem;
    z-index: 1;
    width: 100%;
    background-color: $shadeWhite;
    border-color: $shade7;
    border-bottom-width: 1px;
    border-right-width: 1px;

    .mailActions {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .reloadButtonWrapper {
        margin-left: 0.5rem;
      }
    }

    .searchWrapper {
      padding-top: 1rem;
    }
  }

  .listWrapper {
    display: flex;
    position: relative;
    width: 100%;
    min-height: 100%;
    overflow-y: auto;
    border-right: 1px solid rgb(235, 241, 255);

    &.border-right {
      border-right: 1px solid $shade7;
    }

    .emptyPlaceHolderWrapper {
      padding: 15px;
      text-align: center;
    }

    .loaderNextItem {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
      padding: 15px 0;

      .iconLoader {
        width: 20px;
        height: 20px;
        animation: rotate 4s linear infinite;
        width: fit-content;
        height: fit-content;
        @keyframes rotate {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
}

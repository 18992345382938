@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.container {
  height: 88px;
  border-radius: 4px;
  background-color: var(--fw-color-shades-shade3);
  @include typo-base();
  @include box_shadow();
  color: var(--fw-color-shades-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 30px;
  gap: 2px;
}

.title {
  @include typo-base();
  font-size: toRem(21px);
  font-weight: 600;
  line-height: 28px;
}

.ownerCreatorName {
  font-size: toRem(13px);
  line-height: 30px;
  font-weight: 600;
  font-style: italic;

  @media #{$not-mobile} {
    @include typo-base();
    font-size: toRem(13px);
    line-height: 20px;
  }
}

@use 'src/styles/variables' as *;

.container {
  display: flex;
  flex: 1 1 0%;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
}

.wrapper {
  display: flex;
  align-items: flex-start;
  flex-basis: 33.33%;
  margin-top: 0.5rem;
}

.title {
  font-weight: bold;
}

.container {
  display: flex;
  flex-direction: column;
  .title {
    margin: 10px 0;
    text-align: center;
    font-weight: 500;
    color: var(--fw-color-shades-shade2);
  }
  .description {
    color: var(--fw-color-shades-shade3);
    margin: 10px 0;
  }
  .button {
    margin-top: 10px;
    align-self: center;
  }
}

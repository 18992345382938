@use 'src/styles/variables' as *;

@keyframes pulse {
  27% {
    transform: scale(1, 1);
  }
  48% {
    transform: scale(1.1, 1.1);
  }
  66% {
    transform: scale(1, 1);
  }
  0% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  flex-direction: column;

  .loaderContainer {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    background-color: $primaryDefault;

    svg {
      animation: pulse 1.6s infinite;
    }
  }
}

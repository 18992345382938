@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: transparent;
  margin: 0 20px;
}

.clearFilters {
  align-self: center;
  padding-bottom: 20px;
  @media #{$small-screen} {
    display: none;
  }
}

@import '../../../../styles/variables/media-queries';

.desktop {
  display: block;
  position: fixed;
  z-index: 99;

  @media #{$small-screen} {
    display: none;
  }
}

.mobile {
  display: none;

  @media #{$small-screen} {
    display: block;
    height: 100%;
  }
}

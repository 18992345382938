.infos {
  display: flex;
  height: 100%;
  padding: 12px;
  background-color: var(--fw-color-shades-shade7);
  width: 100%;
  flex-direction: row;
  border-radius: 4px;

  .inactive & {
    background: none;
  }
}

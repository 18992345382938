@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.container {
  padding: 25px 50px;
  height: 30vh;
  width: 100%;

  @media #{$big-screen} {
    width: 50%;
  }
}

:export {
  blue: #4a9dd2;
  white: $color-white-shadow;
  darkGrey: $grey3;
  grey: #dbdce1;
  lightGrey: #f0f7ff;
}

:global {
  /* Surcharge de la gestion des icons de legend de recharts */
  .recharts-legend-item {
    margin-top: 1rem;
    .recharts-surface {
      height: unset;
      width: unset;
    }
  }
}

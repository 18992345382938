@use 'src/styles/legacy/variables' as *;

.color {
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  cursor: pointer;
  margin: 4px;
  &.disabled {
    cursor: not-allowed;
  }
}
.active::before {
  content: '';
  position: absolute;
  display: block;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  border-radius: 100%;
  border: 2px solid $ds-light-9;
}

@use 'src/styles/variables' as *;

table {
  color: $shade2;
  text-align: start;

  .data {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 1rem;

    &.withCheckbox {
      display: flex;
    }

    .dataType {
      font-weight: 600;
      margin-right: 20px;
      white-space: nowrap;
    }

    .previousValue {
      font-style: italic;
      margin-right: 15px;
      word-break: break-word;
    }

    .suggestedValueWithIcon {
      display: flex;
      align-items: center;
      gap: 15px;

      .suggestedValue {
        font-style: italic;
        word-break: break-word;

        &.deletedValue {
          text-decoration: line-through;
        }
      }
    }
  }
}

.approval {
  font-weight: 600;
  font-size: 1rem;
  color: $shade2;
}

@use 'src/styles/variables' as *;

.container {
  flex-grow: 1;
  display: flex;
  background-color: $shade6;
}

.addContentContainer {
  height: 70vh;
  @media #{$small-screen} {
    max-height: 100%;
  }
  flex-grow: 1;
  display: flex;
  background-color: $shade6;
  overflow: auto;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.5);
}

.fileViewerHeaderOwnerCreatorName {
  background-color: $shade7;
  padding: 10px 16px;
  color: $shade4;
  font-style: italic;
}

.fileViewer {
  margin: 10px auto;
  width: stretch;
  display: flex;
  flex: 1;
}

.floatingTile {
  position: fixed;
  z-index: 1;
  border-radius: 20px;
  overflow: hidden;
  right: 25px;

  &.dropZone {
    bottom: 25px;
    max-width: 280px;
    max-height: 260px;
  }

  &.eprescription {
    bottom: 275px;
    max-width: 280px;
    max-height: 260px;
  }

  @media #{$small-screen} {
    display: none;
  }
}

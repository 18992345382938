@use 'src/styles/variables' as *;

.content {
  color: $shade2;
  font-size: 1.125rem;
  line-height: 1.25;
  font-weight: 500;
}

.conclusion {
  color: $shade2;
  font-size: 1.25rem;
  line-height: 1.25;
  font-weight: 600;
}

@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.layoutErrorBoundary {
  display: flex;

  .layoutErrorBoundaryPage {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    margin-left: $menu-left-width;
    @media #{$small-screen} {
      margin-left: 0;
    }
  }

  @media #{$small-screen} {
    flex-direction: column;
  }
}

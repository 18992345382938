@use 'src/styles/variables' as *;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.label {
  margin: 30px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1200px;
  @media #{$medium-screen} {
    max-width: 90%;
  }
  @media #{$small-screen} {
    max-width: 100%;
  }
}

.actions > * {
  margin: 12px;
}

@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.createMedEvent {
  @include typo-input-value();
  border: none;
  cursor: pointer;
  background-color: transparent;
  color: $ds-primary-default;
  font-size: 1.125rem;
  line-height: 1.75rem;

  &:hover {
    color: $primaryDark;
  }
}

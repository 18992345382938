@use 'src/styles/legacy/variables' as *;
@use 'src/styles/variables' as *;

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include typo-base();
  box-shadow: 0px 4px 9px 2px $color-grey-border;
  overflow: hidden;
  border-radius: 8px;
  @media #{$not-mobile} {
    @include box_shadow(1px, 1px, 3px, 2px);
  }
}

.content {
  background-color: $color-content-light-1;
  padding: 10px;
  @media #{$not-mobile} {
    padding: 10px 15px;
  }
}

.observations {
  color: $color-background-dark-1;
  @include typo-input-size-small();
}

.tags {
  display: flex;
  flex-direction: row;
}

.addDocumentButton {
  margin: auto 0 auto 8px;
}

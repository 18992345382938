@use 'src/styles/legacy/variables' as *;
@use 'src/styles/variables' as *;

.container {
  @include navbarStyle();
}

.backButtonWithPageTitle {
  min-height: 72px;
  padding: 0px 24px 0px 24px;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: $ds-dark-2;
  @media #{$small-screen} {
    left: 0px;
    padding: 0px 10px 0px 10px;
  }
}

.backButtonContainer {
  padding-right: 12px;

  @media #{$small-screen} {
    padding-right: 10px;
  }
}

.menuMobileContainer {
  display: none;
  @media #{$small-screen} {
    display: block;
    padding-left: 10px;
  }
}

.title {
  width: 100%;
  @include heading3($font-weight-bold, $ds-white);
  @media #{$small-screen} {
    @include heading5($font-weight-bold, $ds-white);
  }
}

.divider {
  background-color: var(--fw-color-shades-shade4);
  width: 100%;
  height: 5px;
  &.noDivider {
    display: none;
  }
}

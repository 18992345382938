@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.container {
  overflow: hidden scroll;
  width: 30rem;
  padding: 1rem;
  position: relative;
  width: 40rem;
  background-color: $twGray100;
}
.content {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  padding: 1rem;
}

.input_layout {
  padding: 0.75rem;
  border-bottom: 1px solid $shade5;
}

.pregnancy_button {
  margin: 15px;
}

.accordion_title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}

.pregnancy_ended {
  color: $shade5;
}

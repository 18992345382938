@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.dropZone {
  position: fixed !important;
  z-index: $z-index-max;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

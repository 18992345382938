.option {
  max-width: 100%;
  white-space: normal;
  display: grid;
  grid-template-columns: 20px 1fr;
  align-items: center;
  gap: 8px;

  .img {
    justify-self: center;
    height: 14px;
  }

  .phoneCode {
    margin-left: 4px;
    color: var(--fw-color-shades-shade5);
  }
}

@use 'src/styles/variables' as *;

.contact {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 6px 0;
  .infos {
    color: $primaryDefault;
    font-weight: 600;
    cursor: pointer;
  }
}

.sex {
  display: flex;
  align-items: center;
}
.gender {
  padding-inline-end: 5px;
}

.ins {
  display: flex;
  align-items: center;
  .insIcon {
    margin-left: 6px;
  }
}

.phoneNumber {
  color: $primaryDefault;
}

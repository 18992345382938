.list {
  height: 100%;

  .emptyPlaceHolderWrapper {
    padding: 3rem 0;
    height: 100%;
  }

  .addButtonWrapper {
    padding-left: 8px;
  }
}

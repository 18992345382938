/* Z-INDEX */
$z-index-negative: -1;
$z-index-base: 1;
$z-index-10: 10;

$z-index-overlap: 1000;
$z-index-fixed-above: 20000;
$z-index-max: 1000000000;

:export {
  zIndexNegative: $z-index-negative;
  zIndexBase: $z-index-base;
  zIndex10: $z-index-10;

  zIndexOverlap: $z-index-overlap;
  zIndexFixedAbove: $z-index-fixed-above;
  zIndexMax: $z-index-max;
}

@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.container,
.infos {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: toRem(16px);
  flex-grow: 1;
  padding-right: toRem(32px);
}
.actions {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-end;
}
.type {
  font-weight: 600;
  color: $ds-dark-2;
  margin: 0 8px;
}
.date {
  font-weight: 500;
  color: $ds-dark-4;
}
.versionning {
  height: 44px;
  display: flex;
  align-items: center;
}

@use 'src/styles/legacy/variables' as *;

$background-color: transparentize($color-white-shadow, 0.5);
$background-hovered-color: $color-blue-shadow;
$border-radius: 7px;

.container {
  background-color: $background-color;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: $border-radius;
  cursor: pointer;
  height: 4rem;
  margin: 0.5rem 1rem 0 0;
  position: relative;
  transition: background-color 0.2s ease-in-out;
  width: 4rem;

  .baseIcon {
    align-items: center;
    border-radius: $border-radius;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    position: absolute;
    transition: opacity 0.2s ease-in-out;
  }
  .icon {
    opacity: 1;
  }

  .hoverIcon {
    opacity: 0;
  }
  &:hover {
    background-color: $background-hovered-color;
    .icon {
      opacity: 0;
    }
    .hoverIcon {
      opacity: 1;
      background-color: $background-hovered-color;
    }
  }
}

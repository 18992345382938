@use 'src/styles/legacy/variables' as *;

.scoreConfig {
  border: solid 1px $ds-dark-3;
  margin: 1rem;
  padding: 1rem;
}

.error {
  margin-top: 8px;
  color: var(--fw-color-status-invalid);
}
.button {
  max-width: 240px;
  margin-left: auto;
}

@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.closable {
    cursor: pointer;
  }
}

.labelZone {
  user-select: none;
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 0;
}

.iconZone {
  margin: 0px 30px;
  max-height: toRem(20px);
}

.content {
  transition: step-end;
  width: 100%;

  &.filled {
    background-color: var(--fw-color-shades-shade7);

    .filledContentWrapper {
      padding: 0 30px 30px;
    }
  }
}

.simple {
  max-width: 100%;
  width: 100%;
}

.default,
.filled {
  .header {
    background-color: var(--fw-color-shades-shade7);
    transition: background-color 0.2s;
    height: 56px;
    &.closable {
      &:hover {
        background-color: var(--fw-color-shades-shade6);
      }
    }
  }
  .labelZone {
    @include heading5($font-weight-bold, $ds-dark-2);
    margin-left: 30px;
  }
}

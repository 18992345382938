@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.react-datepicker {
  @include fw-elevation-light-top-8();
  @include typo-base();
  background-color: var(--fw-color-primary-default);
  overflow: hidden;
  border: none;

  &__header {
    background-color: var(--fw-color-primary-default);
    border-color: var(--fw-color-primary-default);
    padding: 12px 0 8px;

    &__dropdown {
      padding: 8px 0 4px;

      select {
        background-color: transparent;
        color: var(--fw-color-shades-white);
        border: none;
        font-weight: 600;
        padding: 4px 8px;
        margin-right: 4px;

        option {
          color: var(--fw-color-shades-shade1);
          font-size: 14px;
        }
      }
    }
  }

  &__month-dropdown-container,
  &__year-dropdown-container {
    border: 1px solid var(--fw-color-shades-white);
    border-radius: 24px;
    margin-right: 6px;
  }

  &__month {
    margin: 0;
    padding: 0.5rem 1rem;
    background-color: var(--fw-color-shades-shade8);
  }

  &__current-month {
    text-transform: capitalize;
    color: var(--fw-color-shades-shade8);
    display: none;
  }

  &__today-button {
    background-color: var(--fw-color-primary-default);
    border-color: var(--fw-color-primary-default);
    color: var(--fw-color-shades-shade8);
    padding: 0.5rem;

    &:hover {
      background-color: var(--fw-color-primary-dark);
      border-color: var(--fw-color-primary-dark);
    }
  }

  &__navigation {
    padding-top: 36px;
    &-icon::before {
      border-color: var(--fw-color-shades-white);
    }
  }

  &__month-select,
  &__year-select {
    background-color: var(--fw-color-shades-shade8);
    text-transform: capitalize;
    padding: 2px 4px;
    border-radius: 4px;
    border: none;
  }

  &__day-name {
    color: var(--fw-color-shades-shade8);
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  &__day {
    margin: 2px 4px;

    &--selected {
      background-color: var(--fw-color-primary-default);
    }
  }
}

@use 'src/styles/variables' as *;

.noResultsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .noResultsContent {
    display: flex;
    gap: 40px;

    > svg {
      min-width: 230px;
    }

    .noResultsDescription {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 400px;
      gap: 24px;
      color: $shade2;

      h1 {
        font-size: 28px;
        font-weight: 600;
        line-height: 34px;
      }

      h3 {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }
}

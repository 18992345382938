@use 'src/styles/variables' as *;

.statisticAccessContainer {
  display: flex;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  justify-content: end;
}

.displayStatistic {
  justify-content: start;
}

.statisticAccessWrapper {
  display: grid;
  gap: 0.5rem;
  grid-auto-flow: column;
  width: max-content;
  cursor: pointer;
}

.statisticTitle {
  color: $shade2;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
}

.taskListContainer {
  @media #{$not-mobile} {
    padding: 0 2.5rem 2rem;
  }
}

// body size
$base-font-size: 16px;

// function pixel to rem
@function toRem($pixels, $context: $base-font-size) {
  @return calc($pixels / $context) * 1rem;
}

// spacing
$space-xxs: 4px;
$space-xs: 8px;
$space-sm: 12px;
$space-md: 20px;
$space-lg: 32px;
$space-xl: 52px;
$space-xxl: 84px;
$space-xxxl: 136px;

//border-radiuses
$border-radius: 4px;

// layout
$menu-left-width: 80px;
$mail-navbar-height: 64px;
$navbar-height: var(--navbar-height);
$remaining-width-without-left-menu: calc(100% - $menu-left-width);
$viewport-height-without-navbar: calc(100vh - $navbar-height);

// Sizes used for some hacks
$container-margin-left: 140px;
$legacy-container-padding-top: 15px;

@use 'src/styles/legacy/variables' as *;

.button {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $ds-dark-1;
  margin-left: 0.5rem;

  &:hover {
    color: $ds-primary-default;
    > svg {
      & > g {
        fill: $ds-primary-default;
      }
    }
  }
}
.label {
  margin-left: 0.5rem;
}

.category {
  display: flex;
  height: 100%;
  &:not(:last-child) {
    border-right: 1px solid #ccc;
    padding-inline-end: 7px;
  }
}

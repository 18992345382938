@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.container {
  @include fw-elevation-light-top-4;
  height: toRem(200px);
  width: toRem(200px);
  border-radius: toRem(8px);
  background-color: var(--fw-color-shades-white);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: toRem(8px);
  overflow: hidden;

  @media #{$small-screen} {
    height: toRem(160px);
    width: toRem(160px);
  }

  &:hover {
    @include fw-elevation-light-top-16;
    transition: box-shadow 0.3s ease-in-out;
    cursor: pointer;

    & > .triangle {
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
    }

    & > .labelContainer > .label {
      color: var(--fw-color-primary-default);
    }

    & > .icon {
      fill: var(--fw-color-primary-default);
    }
  }
}

.triangle {
  position: absolute;
  opacity: 0;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: toRem(40px) solid var(--fw-color-primary-default);
  border-left: toRem(40px) solid transparent;

  & > svg {
    position: absolute;
    top: toRem(-35px);
    right: toRem(5px);
  }
}

.icon {
  display: flex;
  align-items: center;
  flex-grow: 0.5;
  fill: var(--fw-color-shades-shade4);
  > svg {
    transform: scale(1.25);
  }
}

.label {
  font-size: toRem(14px);
  font-weight: 500;
  line-height: toRem(24px);
  text-align: center;
  color: var(--fw-color-shades-shade4);
}

.labelContainer {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0.25;
  height: toRem(48px);
}

@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.container {
  width: 100%;
  padding-right: 16px;
  .firstLine {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    .questionTitle {
      @include heading6($font-weight-bold, $ds-dark-2);
    }
  }

  .questionnaires {
    @include footnote($font-weight-normal, $ds-dark-4);
    margin-top: 8px;
    display: flex;
  }
}

@use './fonts.scss' as *;

* {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  &:focus {
    outline: none;
  }
}

html,
body {
  height: 100%;
  // override font smoothing everywhere
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

body {
  margin: 0;
  background: white;
  font-family: $font-family; // font-size: 1.6rem;
  color: #2f2f2f;
}

#root {
  min-height: 100%;
}

main {
  position: relative;
  width: 100%;
  height: 100%;
}

/** RESET **/

a {
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.icon {
  display: inline-block;
  position: relative;
  top: -1px;

  path {
    transition: fill 0.5s ease-in;
  }
}

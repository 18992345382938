@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;
@use './BottomPanel.common.scss' as *;

@media #{$not-mobile} {
  .container {
    z-index: $z-index-fixed-above;
    position: fixed;
    top: 28%;
    transition:
      top $opening-transition,
      left $opening-transition,
      right $opening-transition,
      opacity $opening-transition;
    bottom: 0;
    left: $menu-left-width + $bottomPanelMarginNotMaximized;
    right: $bottomPanelMarginNotMaximized;
    background-color: $ds-dark-2;
    opacity: 1;
    border-radius: 8px 8px 0px 0px;

    &.maximized {
      top: $navbar-height;
      left: $menu-left-width;
      right: 0;
      border-radius: 0px;
    }

    &.hidden {
      top: 100vh;
      bottom: 0;
      opacity: 0;
    }
  }

  .content {
    width: 100%;
    height: calc(100% - $bottomPanelHeaderHeight);
    z-index: $z-index-max;
    opacity: 1;
    position: relative;
  }

  .overlay {
    background-color: #222e42;
    position: fixed;
    opacity: 0.25;
    transition: opacity $opening-transition;
    top: 0;
    right: 0;
    bottom: 0;
    left: $menu-left-width;
    z-index: $z-index-fixed-above;

    &.hidden {
      opacity: 0;
    }
  }

  .mobileActions {
    display: none;
  }
}

@media #{$small-screen} {
  .container {
    position: fixed;
    z-index: 11;
    top: 0;
    left: 0;
    right: 0;
  }
  .overlay {
    display: none;
  }
  .content {
    padding-top: 60px;
    background-color: $color-content-light-1;
    padding-bottom: 60px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
  }
  .hidden {
    display: none;
  }
  .mobileActions {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $ds-dark-2;
    height: 60px;
  }
}

@import '../../../../styles/variables/mixins';
@import '../../../../styles/variables/sizes';

.sharingConfigs {
  display: flex;
  flex-direction: column;

  .title {
    @include typo-base;
    font-size: toRem(12px);
    font-weight: 600;
    color: var(--fw-color-shades-shade4);
    margin-bottom: 4px;
  }

  .tagList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & > * {
      margin: 4px 8px 4px 0;
    }
  }
}

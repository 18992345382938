@use 'src/styles/variables' as *;

.mailLayout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;

  .navbarContainer {
    @include navbarStyle;
  }

  .mailContainer {
    display: flex;
    flex-grow: 1;
    margin-top: $mail-navbar-height;

    .mailList {
      position: fixed;
      z-index: 10;
      height: calc(100vh - $mail-navbar-height);
    }

    .mailDetail {
      width: calc(100% - 20vw);
      margin-left: 20vw;
      background-color: $shadeWhite;
      @media #{$small-screen} {
        width: calc(75vw + $menu-left-width);
      }

      .mailError {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        .errorContainer {
          min-height: calc(100vh - $mail-navbar-height);
        }
      }
    }
  }

  .loading {
    position: relative;
    height: 100%;
    width: 100%;
  }
}

@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.container {
  min-height: inherit;
  transition: margin-left $opening-transition;
  height: 100%;

  @media #{$small-screen} {
    margin-left: 0px;
  }
}

.content {
  min-height: inherit;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
}

.documentContent {
  flex-grow: 1;
  position: relative;
  display: flex;
  overflow: auto;
}

.loadingContainer {
  position: relative;
  height: 100%;
  width: 100%;
}

@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.TimelineHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;

  @media #{$not-mobile} {
    justify-content: flex-start;
  }
}
.label {
  color: var(--fw-color-shades-shade4);
  font-size: toRem(14px);
  font-weight: 600;
  &::first-letter {
    text-transform: uppercase;
  }

  @media #{$not-mobile} {
    font-size: toRem(16px);
    font-weight: 500;
    line-height: 24px;
    margin-left: 1rem;
  }
}

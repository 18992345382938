@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

$fill-indicator-height: 16px;

.container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;

  border-radius: 4px;
  margin: 0px 12px 24px 12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-top-right-radius: 3px; // Parce que je ne sais pas faire un "fillIndicator" arrondi
  transition: box-shadow 0.2s;
  background-color: white;

  @media #{$small-screen} {
    margin: 0px 5px 10px 5px;
  }

  .content {
    padding: toRem(16px);
    max-width: 100%;
  }

  .fillIndicatorContainer {
    border-top-right-radius: 4px;
    overflow: hidden;
    position: absolute;
    right: 0px;

    .fillIndicator {
      right: 0;
      margin-left: auto;
      display: inline-block;
      height: 0;
      width: 0;
      border-top: $fill-indicator-height solid $ds-light-5;
      border-left: $fill-indicator-height solid transparent;
    }
  }

  .questionHeader {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
  }
  .answers {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &.subQuestionPanelOpened {
    border: solid 1px $ds-light-5;
    box-shadow: unset;
  }

  &.filled {
    .fillIndicator {
      border-top: $fill-indicator-height solid $ds-primary-default;
    }
  }

  &.shared {
    .fillIndicator {
      border-top: $fill-indicator-height solid $color-warning;
    }
  }
}

.actions {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  & > *:not(:last-child) {
    margin-right: 8px;
  }
  margin-left: 4px;
}

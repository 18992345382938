.block {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.titleContainer {
  display: flex;

  .title {
    display: flex;
    align-items: center;
    color: var(--fw-color-shades-shade2);
    background-color: var(--fw-color-shades-shade7);
    border-radius: 4px;
    height: 42px;
    padding: 8px 12px;
  }
  .spacer {
    display: flex;
    flex-grow: 1;
    padding: 0 8px;
    align-items: center;
  }
  .icon {
    margin-right: 8px;
  }
}
.blockContent {
  margin: 20px 0;

  & > *:not(:last-child) {
    margin-bottom: 32px;
  }
}

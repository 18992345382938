@use 'src/styles/legacy/variables' as *;

.searchAndResultsContainer {
  overflow-y: scroll;
  background-color: $twGray50;

  .searchContainer {
    padding: 0.5rem 2rem;
  }
}

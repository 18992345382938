@use 'src/styles/legacy/variables' as *;

.suggestionItem {
  padding: 1rem;
}

.createContact {
  display: flex;
  align-items: center;
  color: $ds-dark-4;
  .label {
    margin-left: 0.5rem;
  }

  &:hover {
    color: $ds-light-8;
  }
}

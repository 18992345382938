@use 'src/styles/variables' as *;

.container {
  position: relative;

  .dropdownWrapper {
    position: absolute;
    right: 2.5rem;
    top: 0;
    z-index: 10;
  }

  .dropdownItemLabel {
    margin-left: 0.5rem;
    color: $shade2;
  }
}

@use 'src/styles/legacy/variables' as *;

.scoreValue {
  font-weight: 600;
  margin-inline-start: 0.25rem;

  &.orange {
    color: $color-warning;
  }

  &.green {
    color: $color-success;
  }
  &.red {
    color: $color-error;
  }
}

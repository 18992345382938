@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.placeholder {
  margin: auto;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  * {
    text-align: center;
  }
  svg {
    @include elevation(1);
    flex-grow: 1;
    width: toRem(300px);
    height: toRem(240px);
    margin-bottom: toRem(10px);
    g {
      transform: scale(2);
    }
  }
}

.statistics {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  @media #{$big-screen} {
    flex-direction: row;
  }
}

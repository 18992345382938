@use 'src/styles/variables' as *;

.page {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background-color: $shade8;
  min-height: $viewport-height-without-navbar;
  max-width: calc(100vw - $menu-left-width);
  min-width: 1px;

  margin-left: $menu-left-width;
  margin-top: $navbar-height;
  @media #{$small-screen} {
    max-width: 100%;
    margin-left: 0px;
    &.hidden {
      display: none;
    }
  }

  .pageContent {
    display: flex;
    flex-direction: column;
    position: relative;
    flex-grow: 1;
    width: 100%;
  }
}

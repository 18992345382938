@use 'src/styles/variables' as *;

.eventContainer {
  min-height: 100%;
  padding-top: 60px;

  .eventDetailsContainer {
    display: flex;
    flex-direction: column;
    gap: 40px;
    height: 100%;
    padding: 32px 64px;

    .eventObservationsTitle {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 16px;
      color: $shadeBlack;
    }

    .nextEventButton {
      display: flex;
      justify-content: center;
    }
  }
}
